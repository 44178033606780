import React, {createContext, useContext, useState} from "react";
import {ConfirmDialog} from "./Confirm";


const globalDialogsContext = createContext(null);

export function useGlobalDialogs() {
    return useContext(globalDialogsContext);
}

export function GlobalDialogProvider({children}) {
    const globalDialogs = UseGlobalDialogProvider();
    return <globalDialogsContext.Provider value={globalDialogs}>{children}</globalDialogsContext.Provider>
}

export function GlobalDialogRenderer() {
    const globalDialogs = useGlobalDialogs();
    if (globalDialogs.dialog) return globalDialogs.dialog;
    return <></>;
}

function UseGlobalDialogProvider() {
    const [isVisible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [dialog, setDialog] = useState(null);

    async function withLoading(callback, message) {
        setLoadingMessage(message || "Loading...");
        setLoading(true);
        try {
            await callback();
        }
        finally {
            setLoading(false);
        }
    }

    function showConfirmDialog(props, contents) {
        function GlobalConfirmDialog({title, onCancel, onConfirm, children}) {
            const globalDialogs = useGlobalDialogs();
            return (<ConfirmDialog
                title={title || "Please Confirm"}
                isOpen={globalDialogs.isVisible}
                isLoading={globalDialogs.isLoading}
                loadingMessage={globalDialogs.loadingMessage}
                {...props}
                onConfirm={async () => {
                    if (onConfirm) await onConfirm();
                    globalDialogs.setVisible(false);
                }}
                onCancel={async () => {
                    if (onCancel) await onCancel();
                    globalDialogs.setVisible(false);
                }}
            >{children}</ConfirmDialog>);
        }

        setDialog(<GlobalConfirmDialog {...props}>{contents}</GlobalConfirmDialog>);
        setVisible(true);
    }

    function showDialog(dialog) {
        setDialog(dialog);
        setVisible(true);
    }

    function closeDialog() {
        setDialog(null);
        setVisible(false);
    }

    return {
        dialog,
        setDialog,
        isVisible,
        setVisible,
        isLoading,
        loadingMessage,
        setLoadingMessage,
        setLoading,
        showConfirmDialog,
        withLoading,
        showDialog,
        closeDialog,
    };
}



