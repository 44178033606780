import Modal, {ModalBody, ModalFooter, ModalHeader} from "../Modal";
import {Loading} from "../Loading/Loading";

export function BasicModal({isOpen, title, onClose, children, buttons, isLoading, loadingMessage, showHeader}) {
    if (isOpen === undefined) isOpen = true;
    return (
        <Modal isOpen={isOpen}>
            {showHeader !== false && <ModalHeader>
                <h3>{title}</h3>
                {!isLoading && <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={() => onClose()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                }
            </ModalHeader>}
            <ModalBody>
                {isLoading ? <Loading message={loadingMessage} /> : children}
            </ModalBody>
            {!isLoading && buttons && <ModalFooter>
                {buttons}
            </ModalFooter>
            }
        </Modal>
    );
}