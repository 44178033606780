import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import _ from "lodash";

import TopIconSmall from '../assets/logo/top_logo_white.png';
import TopIconLarge from '../assets/logo/top_logo_words_white.png';
import {useAuth} from "../common/Auth";
import {logger} from "../common/Logger";

export function NavBar() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isToggled, setToggled] = useState(parseInt(localStorage.sidebarToggled));

    const activeParts = location.pathname.split("/").filter(item => item);

    // Save desired toggled state to localStorage.
    localStorage.sidebarToggled = isToggled ? 1 : 0;

    function navigationEventHandler(href, ...args) {
        return (e) => {
            e.preventDefault();
            navigate(href.join('/'), ...args);
            return false;
        };
    }

    function isActive(current, forceActiveExactMatcb) {
        if (!forceActiveExactMatcb) return _.difference(current, activeParts).length === 0;
        return _.intersection(current, activeParts).length === activeParts.length;
    }

    function TopLevelLink({name, href, activeParts, icon, handler, children, navLinkProps}) {
        if (!Array.isArray(href)) href = [href];
        if (!handler) handler = navigationEventHandler(href);
        if (typeof activeParts === 'undefined') activeParts = href;

        const activeClass = activeParts && isActive(activeParts) ? "active" : "";

        if (!navLinkProps) navLinkProps = [];

        return (
            <li className={"nav-item " + activeClass}>
                <a className="nav-link" href={"/" + href} onClick={handler} {...navLinkProps}>
                    <i className={"fas fa-fw " + icon}></i>
                    <span>{name}</span>
                </a>
                {children}
            </li>
        );
    }

    function TopLevelLinkDropDown({name, activeParts, icon, children}) {
        const isMenuActive = activeParts && isActive(activeParts);
        const activeClass = isMenuActive ? "active" : "";

        const dropID = "nav-drop-" + (Date.now().toString()) + "-" + Math.floor(Math.random() * 1000).toString();

        return (
            <li className={"nav-item " + activeClass}>
                <a className="nav-link" href="#" data-toggle="collapse" data-target={"#" + dropID}>
                    <i className={"fas fa-fw " + icon}></i>
                    <span>{name}</span>
                </a>

                <div id={dropID} className={"collapse " + (!isToggled && isMenuActive ? "show" : "")}>
                    <div className="bg-white py-2 collapse-inner rounded">
                        {children}
                    </div>
                </div>
            </li>
        );
    }

    function DropDownLink({title, href, handler, activeParts, forceActiveExactMatcb}) {
        if (!Array.isArray(href)) href = [href];
        if (!handler && href) handler = navigationEventHandler(href);
        if (typeof activeParts === 'undefined') activeParts = href;

        const isMenuActive = activeParts && isActive(activeParts, forceActiveExactMatcb);

        return (
            <a className={"collapse-item " + (isMenuActive && "active")} href={"/" + href} onClick={handler}>{title}</a>
        );

    }

    function renderBrand() {
        if (isToggled) {
            return (
                <div className="sidebar-brand-icon-toggled">
                    <img style={{
                        width: '40px',
                        height: '40px'
                    }} src={TopIconSmall} />
                </div>
            );
        }

        return (<>
            <div className="sidebar-brand-icon-toggled">
                <img style={{
                    width: '100%',
                }} src={TopIconLarge} />
            </div>
        </>);
    }

    return <ul className={"navbar-nav bg-gradient-primary sidebar sidebar-dark accordion " + (isToggled ? "toggled" : "")} id="accordionSidebar">
        <a className="sidebar-brand d-flex align-items-center justify-content-center">
            {renderBrand()}
        </a>

        <hr className="sidebar-divider my-0"/>

        <TopLevelLink
            name="Dashboard"
            href="dashboard"
            icon="fa-tachometer-alt"
        />

        <TopLevelLink
            name="Map"
            href="map"
            icon="fa-map"
        />

        <TopLevelLink
            name="Reports"
            href="reports"
            icon="fa-clipboard"
        />

        <TopLevelLink
            name="Projects"
            href="projects"
            icon="fa-folder"
        />

        <TopLevelLink
            name="Recent Events"
            href="events"
            icon="fa-clock"
        />

        <TopLevelLink
            name="Devices"
            href="devices"
            icon="fa-tablet"
        />

        <TopLevelLink
            name="Users"
            href="users"
            icon="fa-users"
        />

        <TopLevelLink
            name="Settings"
            href="settings"
            icon="fa-cog"
        />

        <hr className="sidebar-divider d-none d-md-block" />
        <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle" onClick={() => {
                setToggled(!isToggled);
            }}></button>
        </div>


        <div className="" style={{backgroundColor: 'darkblue', color: 'white', position: 'fixed', bottom: 0}}>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropup">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img className="img-profile rounded-circle" src="/vendor/sbadmin2/img/undraw_profile.svg" />
                        <span className="ml-2 d-none d-lg-inline text-gray-600 small">Welcome, {auth.user.get('fullName')}</span>
                    </a>

                    <div className="dropdown-menu dropdown-menu-top shadow" aria-labelledby="userDropdown">
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Settings
                        </a>
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#" onClick={e => {
                            e.preventDefault();
                            auth.logout().then(() => {
                                logger.info("Navbar Logout Success");
                            })
                        }}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </a>
                    </div>
                </li>
            </ul>
        </div>


    </ul>
}