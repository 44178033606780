import {useEffect, useRef} from "react";
const $ = window.$;

export function ButtonWithTooltip({children, ...props}) {
    const $el = useRef();

    // useEffect(() => {
    //     $(document).ready(function() {
    //         $('[data-toggle="tooltip"]', $el.current).tooltip()
    //     });
    // })

    return (
        <button ref={$el} className="btn btn-primary" data-toggle="tooltip" {...props}>
            {children}
        </button>
    );
}