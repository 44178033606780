import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import {Database} from "@nozbe/watermelondb";
import withObservables from '@nozbe/with-observables'
import schema from "../schema/schema";
import migrations from "../schema/migrations";

import {getLastPulledAt} from "@nozbe/watermelondb/sync/impl";
import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync'

import {UserModel} from "../schema/model/UserModel";
import {ProjectModel} from "../schema/model/ProjectModel";
import {AccountProjectModel} from "../schema/model/AccountProjectModel";
import {AccountModel} from "../schema/model/AccountModel";
import {ReportModel} from "../schema/model/ReportModel";
import {FireStore} from "../config/firebase";
import {syncMelonFire} from "./syncengine.ts";
import {useAuth} from "./Auth";
import {MarkerModel} from "../schema/model/MarkerModel";
import {EventModel} from "../schema/model/EventModel";
import {DeviceModel} from "../schema/model/DeviceModel";
import {DeviceStatusModel} from "../schema/model/DeviceStatusModel";
import {DeviceConfigModel} from "../schema/model/DeviceConfigModel";

const adapter = new LokiJSAdapter({
    schema,
    // (You might want to comment out migrations for development purposes -- see Migrations documentation)
    // migrations,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    dbName: 'trapmaster',

    onQuotaExceededError: (error) => {
        // Browser ran out of disk space -- offer the user to reload the app or log out
    },
    onSetUpError: (error) => {
        // Database failed to load -- offer the user to reload the app or log out
    },
    extraIncrementalIDBOptions: {
        onDidOverwrite: () => {
            // Called when this adapter is forced to overwrite contents of IndexedDB.
            // This happens if there's another open tab of the same app that's making changes.
            // Try to synchronize the app now, and if user is offline, alert them that if they close this
            // tab, some data may be lost
        },
        onversionchange: () => {
            // database was deleted in another browser tab (user logged out), so we must make sure we delete
            // it in this tab as well - usually best to just refresh the page
            window.location.reload()
        },
    }
});

// Then, make a Watermelon database from it!
const database = new Database({
    adapter,
    modelClasses: [
        UserModel,
        AccountModel,
        ProjectModel,
        AccountProjectModel,
        ReportModel,
        MarkerModel,
        EventModel,
        DeviceModel,
        DeviceStatusModel,
        DeviceConfigModel
    ],
});

window.wdb = database;

window.testDB = async function() {

    async function dumpData() {
        console.log("Fetching user records...");
        const users = await database.get('user').query().fetch();
        console.log(users);

        console.log("Fetching account records...");
        const accounts = await database.get('account').query().fetch();
        console.log(accounts);

        console.log("Fetching project records...");
        const projects = await database.get('project').query().fetch();
        console.log(projects);

        console.log("Fetching account_project records...");
        const accountprojects = await database.get('account_project').query().fetch();
        console.log(accountprojects);
    }

    console.group("WatermelonDB Testing");
    // console.log("Nuking Database Records...");
    // await database.write(async () => {
    //     await database.unsafeResetDatabase();
    // });

    // Ensure empty...
    await dumpData();

    let accountRef = null;
    let userID = null;
    let accountID = null;
    let projectID = null;
    let project2Ref = null;

    // Setup observer.
    const observer = await database.get('user').query().observe();

    const subscription = observer.subscribe((...args) => {
        console.warn("Query Updated: ", args);
    });

    // Create basic structure.
    console.log("Creating new Account Record....");
    await database.write(async () => {
        const account = await database.get('account').create(account => {
            account.name = "Simplex Media LTD";
        });
        accountID = account.id;
        accountRef = account;

        const user = await database.get('user').create(user => {
            user.name = "Ryan";
            user.lastName = "Tiedemann";
            user.account.set(account);
        });
        userID = user.id;

        const project = await database.get('project').create(project => {
            project.name = "A new test project!";
        });
        projectID = project.id;

        const project2 = await database.get('project').create(project => {
            project.name = "Another project that we dont want!";
        });

        project2Ref = project2;

        console.log("Adding account to project.");
        const account_project = await database.get('account_project').create(accountproject => {
            accountproject.project.set(project);
            accountproject.account.set(account);
        });
    });

    window.accountID = accountID;

    // Read some data back.
    await dumpData();

    // const account = await database.get('account').find(accountID);
    // console.log("Account: ", account);
    // console.log("Name: ", account.name);
    // console.log("ProjectS: ", await account.projects.fetch());
    //
    // console.log("Users: ", await account.users.fetch());
    //
    // const user = await database.get('user').find(userID);
    // console.log("User: ", user);
    // console.log("Name: ", user.name);
    // console.log("User Accounts:", await user.account.fetch());
    // console.log("User Projects: ", await user.getProjects());


    console.log(observer);

    console.log("Creating another user");
    // Create another new user, we should be notified.
    await database.write(async () => {
        const user = await database.get('user').create(user => {
            user.name = "Test";
            user.lastName = "User";
            user.account.set(accountRef);
        });
    });

    // Stop watching...
    subscription.unsubscribe();

    // Shouldn't be notified.
    console.log("Creating another user 2");
    await database.write(async () => {
        const user = await database.get('user').create(user => {
            user.name = "Test2";
            user.lastName = "User2";
            user.account.set(accountRef);
        });
    });

    // Setup listener 2.
    const sub2 = observer.subscribe((result) => {
        console.warn("Second Observer", result);
    });


    console.log("Done");
    console.groupEnd();
}

function getSyncReference(accountID) {
    return FireStore.collection('sync_point').doc(accountID);
}

async function sync(accountID) {
    return false;
    // return await syncMelonFire(database, getSyncReference(accountID));
}

async function getLastPulledRevision() {
    return await getLastPulledAt(database);
}

async function checkForUpdates(accountID) {
    // Check if we have any changes to push.
    const localChanges = await hasUnsyncedChanges({database});
    if (localChanges) return true;

    const SyncReference = getSyncReference(accountID);
    const latestRevisionLocal = await getLastPulledRevision();
    const SyncReferenceSnap = await SyncReference.get();
    if (SyncReferenceSnap.exists)
    {
        const SyncReferenceData = SyncReferenceSnap.data();
        if (SyncReferenceData.hasOwnProperty('melonLatestRevision'))
        {
            const latestRevisionRemote = SyncReferenceData.melonLatestRevision;
            if (latestRevisionLocal > latestRevisionRemote) return false;
        }
    }

    return true;
}

async function SyncIfRequired(accountID) {
    if (await checkForUpdates(accountID))
    {
        await sync(accountID);
        return true;
    }
    return false;
}

window.shouldSync = checkForUpdates;

export {
    database,
    sync,
    checkForUpdates,
    SyncIfRequired,
    getLastPulledRevision
}