import {PageHeader} from "../component/PageHeader";
import {EventStatsProvider, useEventStats} from "../component/EventStats/Provider";
import {EventFeedCounterWidget} from "../component/EventStats/EventFeedCounterWidget";
import {EventTypes} from "../config/EventTypes";


export function DashboardPage() {
    return (
        <EventStatsProvider>
            <div>
                <PageHeader title="Dashboard"/>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <EventFeedCounterWidget
                            feed="CaughtFeed"
                            title="Caught (last 30 days)"
                            border="border-left-success"
                            start={Date.now() - (86400000 * 30)}
                            type={EventTypes.TRAP_CATCH}
                            icon="fas fa-paw fa-2x"
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <EventFeedCounterWidget
                            feed="CaughtFeed"
                            title="Caught (last 12 months)"
                            border="border-left-success"
                            start={Date.now() - (86400000 * 365)}
                            type={EventTypes.TRAP_CATCH}
                            icon="fas fa-paw fa-2x"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <EventFeedCounterWidget
                            feed="SprungFeed"
                            title="Sprung (last 30 days)"
                            border="border-left-warning"
                            start={Date.now() - (86400000 * 30)}
                            type={EventTypes.TRAP_SPRUNG}
                            icon="fas fa-exclamation fa-2x"
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <EventFeedCounterWidget
                            feed="SprungFeed"
                            title="Sprung (last 12 months)"
                            border="border-left-warning"
                            start={Date.now() - (86400000 * 365)}
                            type={EventTypes.TRAP_SPRUNG}
                            icon="fas fa-exclamation fa-2x"
                        />
                    </div>
                </div>
            </div>
        </EventStatsProvider>
    );
}