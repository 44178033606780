import {PageHeader} from "../component/PageHeader";
import {database, getLastPulledRevision, sync} from "../common/DB";
import {useAuth} from "../common/Auth";
import {useSync} from "../common/Sync";
import SimplexMediaLogo from "../assets/logo/simplex_media.png";


export function SettingsPage() {
    const auth = useAuth();
    const sync = useSync();

    return <div>
        <PageHeader title="Settings"/>
        <div className="row">
            <div className="col-12">
                <h5>Account Statistics: </h5>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div>Account ID: {auth.account.id}</div>
                <div>Account Name: {auth.account.get('name')}</div>
                <div>User ID: {auth.user.id}</div>
                <div>User Name: {auth.user.get('name')}</div>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col-12">
                <h5>Company Logo: </h5>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <img style={{width: '100%'}} src={SimplexMediaLogo}/>
            </div>
            <div className="col-6">
                <button className="btn btn-success btn-icon-split" disabled={false} onClick={async () => {
                    alert("TODO")
                }}>
                    <span className="text">Upload Logo</span>
                    <span className="icon text-white-50">
                        <i className="fa fa-upload"/>
                    </span>
                </button>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col-12">
                <h5>Developer Settings: </h5>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <button className="btn btn-success btn-icon-split" disabled={false} onClick={async () => {
                    await sync.syncDB();
                }}>
                    <span className="text">{sync.isSyncing ? "Syncing..." : "Sync Database"}</span>
                    <span className="icon text-white-50">
                            <i className="fa fa-plus"/>
                        </span>
                </button>

                <button className="btn btn-success btn-icon-split ml-1" disabled={false} onClick={async () => {
                    if (window.confirm("Are you sure you want to reset the local database?")) {
                        await database.write(async () => {
                            await database.unsafeResetDatabase();
                        });
                        window.location.reload();
                    }
                }}>
                    <span className="text">Reset Database</span>
                    <span className="icon text-white-50">
                        <i className="fa fa-trash"/>
                    </span>
                </button>
            </div>
        </div>
    </div>
}