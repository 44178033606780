import {Loading} from "../Loading/Loading";

export function BasicCard({isLoading, loadingMessage, title, bodyClassName, children}) {
    return (
        <div className="card shadow mb-4">
            {title && <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">{title}</h6>
            </div>}

            <div className={"card-body " + bodyClassName}>
                {isLoading ? <Loading message={loadingMessage}/> : children}
            </div>
        </div>
    );
}