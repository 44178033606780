import {PageHeader} from "../component/PageHeader";
import {getLastPulledRevision, sync} from "../common/DB";
import {useAuth} from "../common/Auth";
import {useSync} from "../common/Sync";
import {useCallback, useState} from "react";
import {MyWindowPortal, NewWindow} from "../WindowPortal";
import {BodySwapPortal, usePortalManager} from "../component/BodySwapPortal";


export function TestPage() {
    const [isOpen, setOpenState] = useState(false);
    const open = useCallback(() => setOpenState(true));
    const close = useCallback(() => setOpenState(false));

    return <div>
        <PageHeader title="Test Page"/>

        <div>
            <h1>BodySwapPortal in React</h1>
            <button onClick={open}>Open</button>
            <button onClick={close}>Close</button>
            {isOpen && (
                <BodySwapPortal onRequestClose={close}>
                    <h5>Hello World!</h5>
                    <button onClick={() => {
                        close();
                    }}>Close Me</button>
                </BodySwapPortal>
            )}
        </div>
    </div>
}