import {Model, Q} from "@nozbe/watermelondb";
import {date, readonly, text, children, lazy, immutableRelation} from "@nozbe/watermelondb/decorators";

export class AccountModel extends Model {
    static table = "account";

    static associations = {
        account_project: { type: 'has_many', foreignKey: 'account_id' },
        user: { type: 'has_many', foreignKey: 'account_id' }
    }

    @text('name') name;
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt

    @children('user') users;
    @lazy projects = this.collections.get('project').query(Q.on('account_project', 'account_id', this.id));
}
