import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {useEffect, useRef} from "react";
import {useMap} from "./MapView";

export function MapboxPopup({children, lnglat, ...mapboxPopupProps}) {
    const map = useMap();
    const popupRef = useRef();

    useEffect(() => {
        const popup = new mapboxgl.Popup({
            closeButton: true,
            ...mapboxPopupProps
        })
            .setLngLat(lnglat)
            .setDOMContent(popupRef.current)
            .addTo(map);

        return () => popup.remove();
    }, [children, lnglat, map]);

    return (
        <div style={{display: "none"}}>
            <div ref={popupRef}>{children}</div>
        </div>
    );
}