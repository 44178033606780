import {useMap} from "./MapView";
import {useEffect, useMemo} from "react";
import {randomstr} from "../../common/Util";

export function BlockHighlight({bounds}) {
    const map = useMap();
    const unique_id = useMemo(() => randomstr(32), []);

    useEffect(() => {
        const sourceID = unique_id + '_source';
        const layerID = unique_id + '_layer';
        const outlineID = unique_id + '_outline';
        window.bounds = bounds;

        map.addSource(sourceID, {
            'type': 'geojson',
            'data': bounds
        });

        // Add a new layer to visualize the polygon.
        map.addLayer({
            'id': layerID,
            'type': 'fill',
            'source': sourceID,
            'layout': {},
            'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': 0.3
            },
        });

        // Add a black outline around the polygon.
        map.addLayer({
            'id': outlineID,
            'type': 'line',
            'source': sourceID,
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 3
            }
        });

        return () => {
            map.removeLayer(outlineID);
            map.removeLayer(layerID);
            map.removeSource(sourceID);
        }
    }, [map]);

    useEffect(() => {
        const source = map.getSource('block_boundary');
        if (source) source.setData(bounds);
    }, [bounds]);

    return (<></>);
}