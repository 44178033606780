import {Model} from "@nozbe/watermelondb";
import {date, field, immutableRelation, readonly} from "@nozbe/watermelondb/decorators";

export class AccountProjectModel extends Model {
    static table = "account_project";
    static associations = {
        account: { type: 'belongs_to', foreignKey: 'account_id' },
        project: { type: 'belongs_to', foreignKey: 'project_id' },
    }

    @field('account_id') accountID;
    @field('project_id') projectID;

    @immutableRelation('account', 'account_id') account
    @immutableRelation('project', 'project_id') project

    @readonly @date('created_at') createdAt
}
