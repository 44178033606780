import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

export function GeoJson (markers) {
    const features = [];
    const bounds = new mapboxgl.LngLatBounds();
    for (const marker of markers) {
        features.push({
            type: "Feature",
            properties: {
                marker: marker.id,
                project: marker.project_id,
            },
            geometry: {
                type: "Point",
                "coordinates": [marker.longitude, marker.latitude]
            }
        });
        bounds.extend([marker.longitude, marker.latitude]);
    }

    const gj = {
        type: 'FeatureCollection',
        features: features
    };

    if (markers.length) gj.bounds = bounds;
    return gj;
}