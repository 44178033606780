import React, {createContext, useContext, useEffect, useState} from "react";
import {CreateLogger} from "../common/logger/Logger";

const logger = CreateLogger('BodySwapPortal');

const portalContext = createContext({
    isReady: false
});

export function usePortalManager() {
    return useContext(portalContext);
}

export function MountedHook({children, onMounted, onRemoved}) {
    useEffect(() => {
        if (onMounted) onMounted();

        return () => {
            if (onRemoved) onRemoved();
        }
    }, []);

    return <>{children}</>
}

export class BodySwapPortal extends React.Component {

    componentDidMount() {
        logger.log("Portal Opening...");
        this.context.setTarget(
            <MountedHook
                onMounted={() => {
                    console.log("I AM MOUNTED");
                    if (this.props.onOpened) this.props.onOpened();
                }}
                onRemoved={() => {
                    if (this.props.onRequestClose) this.props.onRequestClose();
                }}
            >
                <div className="bodyswapportal-out">{this.props.children}</div>
            </MountedHook>
        );
        this.context.open();

        if (this.props.onRequestClose) {
            this.escapeListener = event => {
                if (event.keyCode === 27) this.props.onRequestClose();
            }
            document.addEventListener("keydown", this.escapeListener);
        }
    }

    componentWillUnmount() {
        logger.log("Portal Closing...");
        if (this.escapeListener) document.removeEventListener("keydown", this.escapeListener);
        this.context.setTarget(null);
        this.context.close();
    }

    render() {
        return <></>;
    }
}

BodySwapPortal.contextType = portalContext;

function PortalGateway({children}) {
    const PortalManager = usePortalManager();
    const [isActive, setActive] = useState(false);


    useEffect(() => {
        if (PortalManager.isReady && PortalManager.isOpen && PortalManager.target) {
            setActive(true);
        }
        else if (isActive) setActive(false);
    }, [PortalManager]);

    const otherStyle = {};
    let targetRendered = <></>;
    if (isActive) {
        targetRendered = PortalManager.target;
        otherStyle.display = 'none';
    }

    return (
        <>
            {targetRendered}
            <div id='bodyswapportalgateway' style={otherStyle}>{children}</div>
        </>
    );
}

export function BodySwapPortalProvider({children}) {
    const manager = PortalManager();
    return <portalContext.Provider value={manager}>
        <PortalGateway>
            {children}
        </PortalGateway>
    </portalContext.Provider>
}

function PortalManager() {
    const [isOpen, setOpen] = useState(false);
    const [target, setTarget] = useState(false);

    return {
        isReady: true,
        isOpen,
        open: () => setOpen(true),
        close: () => setOpen(false),
        target,
        setTarget
    };
}