import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {useMap} from "../MapView";
import {useEffect, useState} from "react";
import {MapboxPopup} from "../MapboxPopup";
import {Loading} from "../../Loading/Loading";
import {database} from "../../../common/DB";
import {Q} from "@nozbe/watermelondb";
import {EventTypes} from "../../../config/EventTypes";
import {useNavigate} from "react-router-dom";
import {GeoJson} from "../../../common/map";
import {CreateLogger} from "../../../common/logger/Logger";

const logger = CreateLogger('MakerLayer');

export function MarkerLayer({markers, geojson, onInit}) {
    const map = useMap();
    const [popups, setPopups] = useState([]);
    const [isInited, setInited] = useState(false);

    useEffect(() => {
        map.addSource('markers', {
            type: 'geojson',
            data: geojson || {
                type: 'FeatureCollection',
                features: []
            }
        });

        map.addLayer({
            'id': 'markers-point',
            'type': 'circle',
            'source': 'markers',
            'paint': {
                'circle-radius': 5,
                'circle-color': 'white',
                'circle-stroke-color': 'black',
                'circle-stroke-width': 1,
            }
        });

        map.on('click', 'markers-point', (e) => {
            // Copy coordinates array.
            const marker = e.features[0].properties.marker;
            const coordinates = e.features[0].geometry.coordinates.slice();

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Center the marker in the screen
            map.flyTo({
                center: coordinates,
                speed: 0.5
            });

            setPopups([<MarkerPopup key={marker} markerID={marker} projectID={e.features[0].properties.project} lnglat={coordinates} maxWidth="500px" />]);
        });

        map.on('mouseenter', 'markers-point', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'markers-point', () => {
            map.getCanvas().style.cursor = '';
        });

        return () => {
            map.removeLayer('markers-point');
            map.removeSource('markers');
        }
    }, [map]);

    useEffect(() => {
        const markerSource = map.getSource('markers');
        let markerData;
        if (!geojson && markers) {
            logger.warn("Using Raw Markers...", geojson, markers);
            if (markerSource) {
                markerData = GeoJson(markers);
                markerSource.setData(markerData);
            }
        }
        else if (geojson) {
            markerSource.setData(geojson);
        }

        if (onInit && !isInited) {
            onInit(map, markerSource, markerData);
        }
    }, [map, markers, geojson]);

    return <>{popups}</>;
}

export function MarkerPopup({markerID, projectID, ...popupProps}) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [marker, setMarker] = useState(null);
    const [markerStats, setMarkerStats] = useState({
        caught: 0,
        sprung: 0,
    });

    useEffect(() => {
        const subscription = database.get('marker').findAndObserve(markerID).subscribe(async marker => {
            await setMarker(marker);
        });

        return () => subscription.unsubscribe();
    }, [markerID]);

    useEffect(() => {
        if (!marker) return false;

        async function countEventType(type) {
            const conditions = [Q.where('marker_id', markerID),];
            if (type) conditions.push(Q.where('type', type));
            return await database.get('event').query(...conditions).fetchCount();
        }

        (async() => {
            const caughtCount = await countEventType(EventTypes.TRAP_CATCH);
            const sprungCount = await countEventType(EventTypes.TRAP_SPRUNG);

            setMarkerStats({
                caught: caughtCount,
                sprung: sprungCount
            });
            setLoading(false);
        })();
    }, [markerID, marker, setMarkerStats]);

    return (
        <MapboxPopup {...popupProps}>
            {isLoading
            ? <Loading message="Loading Marker..."/>
            : <div>
                    <h4>ID: {marker.id}</h4>
                    <table>
                        <tbody>
                            <tr>
                                <th>Caught:</th>
                                <td>{markerStats.caught}</td>
                            </tr>
                            <tr>
                                <th>Sprung:</th>
                                <td>{markerStats.sprung}</td>
                            </tr>
                            <tr>
                                <th>Placed On:</th>
                                <td>{marker.createdAt.toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <th>Placed By:</th>
                                <td>{marker.createdBy.id}</td>
                            </tr>
                            <tr>
                                <th>Project:</th>
                                <td>{marker.project.name}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <button className="btn btn-success btn-icon-split" onClick={() => {
                                        navigate("/events?marker=" + marker.id);
                                    }}>
                                        <span className="text">Show Events</span>
                                        <span className="icon text-white-50"><i className="fas fa-clock"/></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
              </div>
            }
        </MapboxPopup>
    );
}