export function InputGroupWithIcon({icon, children}) {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                    <i className={icon || "fa fa-pencil-alt"}/>
                </span>
            </div>
            {children}
        </div>
    );
}