import {Model, Q} from "@nozbe/watermelondb";
import {date, readonly, text, field, immutableRelation, writer, lazy, children} from "@nozbe/watermelondb/decorators";

export class DeviceModel extends Model {
    static table = "device";

    static associations = {
        device_status: { type: 'has_many', foreignKey: 'device_id' },
        device_config: { type: 'has_many', foreignKey: 'device_id' }
    }

    @text('name') name;
    @text('device_hash') deviceHash;
    @field('account_id') accountID;

    @immutableRelation('account', 'account_id') account;

    @children('device_config', 'device_hash') config;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy status = this.collections.get('device_status').query(
        Q.where('device_id', this.deviceHash),
        Q.sortBy('created_at', Q.desc),
        Q.take(1)
    );

    @writer async delete() {
        await this.markAsDeleted();
    }
}
