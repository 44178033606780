import {Model} from "@nozbe/watermelondb";
import {date, immutableRelation, lazy, readonly, text, field} from "@nozbe/watermelondb/decorators";

export class UserModel extends Model {
    static table = "user";


    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt

    // A user can only belong to a single account.
    @immutableRelation('account', 'account_id') account;

    @text('name') name;
    @text('last_name') lastName;
    @date('last_active') lastActive;
    @text('email') email;
    @field('active') isActive;


    async getProjects() {
        const account = await this.account.fetch();
        if (!account) throw new Error("User is not associated with an account!");
        return await account.projects.fetch();
    }
}