import {BasicModal} from "./BasicModal";

export function ConfirmDialog({isOpen, isLoading, loadingMessage, children, title, message, confirmText, cancelText, onCancel, onConfirm}) {
    return (
        <BasicModal
            isOpen={isOpen}
            title={title}
            onClose={onCancel}
            isLoading={isLoading}
            loadingMessage={loadingMessage}
            buttons={<>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onCancel()}
                >{cancelText || "Cancel"}</button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onConfirm()}
                >{confirmText || "Confirm"}</button>
            </>}
        >
            {children || <p>{message}</p>}
        </BasicModal>
    );
}