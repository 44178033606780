const LOGLEVEL = Object.freeze({
    ALL: 0,
    LOG: 10,
    INFO: 20,
    WARN: 30,
    ERROR: 40,
    CRITICAL: 50
});

const LOGLEVELTAG = Object.freeze({
    [LOGLEVEL.ALL]: '',
    [LOGLEVEL.LOG]: ' LOG',
    [LOGLEVEL.INFO]: ' INFO',
    [LOGLEVEL.WARN]: ' WARN',
    [LOGLEVEL.ERROR]: ' ERROR',
    [LOGLEVEL.CRITICAL]: ' CRITICAL'
});

const LOGPRIO = Object.freeze({
    [LOGLEVEL.ALL]: 0,
    [LOGLEVEL.LOG]: 10,
    [LOGLEVEL.INFO]: 20,
    [LOGLEVEL.WARN]: 30,
    [LOGLEVEL.ERROR]: 40,
    [LOGLEVEL.CRITICAL]: 50
});

const OUTWRITE = Object.freeze({
    [LOGLEVEL.ALL]: console.log,
    [LOGLEVEL.LOG]: console.log,
    [LOGLEVEL.INFO]: console.info,
    [LOGLEVEL.WARN]: console.warn,
    [LOGLEVEL.ERROR]: console.error,
    [LOGLEVEL.CRITICAL]: console.error
});

class Logger {
    constructor(tag, options) {
        this.tag = tag;
        this.options = Object.assign({}, options);
    }

    _write(level, tags, options, ...args) {
        if (!LOGLEVEL.hasOwnProperty(level)) level = LOGLEVEL.ALL;
        let preamble = "";
        if (options.time) preamble += Date.now() + " ";
        preamble += "[" + tags.join('.') + "]" + LOGLEVELTAG[level] + ":";
        OUTWRITE[level](preamble, ...args);
    }
    _log(level, ...args) {
        if (this.options.parent) this.options.parent._fromchild(level, [this.tag], this.options, ...args);
        else {
            this._write(level, [this.tag], this.options, ...args);
        }
    }

    _fromchild(level, tags, options, ...args) {
        tags.splice(0, 0, this.tag);
        options = Object.assign({}, this.options, options);
        if (this.options.parent) this.options.parent._fromchild(level, tags, options, ...args);
        else {
            this._write(level, tags, options, ...args);
        }
    }

    log(...args) {
        this._log(LOGLEVEL.LOG, ...args);
    }
    info(...args) {
        this._log(LOGLEVEL.INFO, ...args);
    }

    warn(...args) {
        this._log(LOGLEVEL.WARN, ...args);
    }

    error(...args) {
        this._log(LOGLEVEL.ERROR, ...args);
    }

    critical(...args) {
        this._log(LOGLEVEL.CRITICAL, ...args);
    }
}

export const MainLogger = new Logger('TMO2', {});

const LOGGERS = new Map();
export function CreateLogger(tag, options) {
    if (!LOGGERS.has(tag)) {
        LOGGERS.set(tag, new Logger(tag, Object.assign({}, {
            parent: MainLogger
        }, options)));
    }
    return LOGGERS.get(tag);
}