import {InputGroupWithIcon} from "./InputGroupWithIcon";

export function TextInputWithIcon({title, icon, placeholder, defaultValue, value, onChange, inputRef}) {
    return (
        <div className="form-group">
            <label htmlFor="basicInput">{title}</label>
            <InputGroupWithIcon icon={icon}>
                <input
                    ref={inputRef}
                    type="text"
                    className="form-control"
                    id="basicInput"
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                />
            </InputGroupWithIcon>
        </div>
    );
}