import {useEffect, useState} from "react";
import {useEventStats} from "./Provider";
import {Loading} from "../Loading/Loading";
import {database} from "../../common/DB";
import {Q} from "@nozbe/watermelondb";
import {QueryFirstSubscribe} from "../../common/Util";
import {CreateLogger} from "../../common/logger/Logger";

const logger = CreateLogger('EventFeedCounterWidget');
export function EventFeedCounterWidget({border, type, title, icon, start, end, formatter}) {
    const [isLoading, setLoading] = useState(true);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const conditions = [];
        if (type) conditions.push(Q.where('type', type));
        if (start) conditions.push(Q.where('created_at', Q.gte(start)));
        if (end) conditions.push(Q.where('created_at', Q.lt(end)));

        const subscription = QueryFirstSubscribe(
            database.get('event').query(...conditions).observeCount(false),
            () => setLoading(false),
            frame => setCount(frame)
        );

        return () => subscription.unsubscribe();
    }, [type, start, end]);

    return (
        <div className={"card shadow h-100 py-2 " + border}>
            <div className="card-body">
                {(isLoading) && <Loading message="Loading Events..." />}
                {(!isLoading) && <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{title}</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{formatter ? formatter(count) : count}</div>
                    </div>
                    <div className="col-auto">
                        <i className={"text-gray-300 " + icon}></i>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}