import {Model} from "@nozbe/watermelondb";
import {date, readonly, text, field, immutableRelation, writer, json} from "@nozbe/watermelondb/decorators";

export class DeviceStatusModel extends Model {
    static table = "device_status";

    @field('latitude') latitude;
    @field('longitude') longitude;

    @field('device_id') device_id;
    @field('user_id') user_id;

    @immutableRelation('device', 'device_id') device;
    @immutableRelation('account', 'user_id') user;

    @json('info', json => json) info;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @writer async delete() {
        await this.markAsDeleted();
    }
}
