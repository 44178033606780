import {useEffect, useRef} from "react";
import _ from "lodash";
const $ = window.$;

export function DateRangePicker({pickerOptions, startDate, endDate, onChange}) {
    let inputRef = useRef();

    useEffect(() => {
        $(inputRef.current).daterangepicker(_.merge({
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            autoApply: true
        }, pickerOptions), (start, end, label) => {
            onChange({
                startDate: start.toDate(),
                endDate: end.toDate(),
            });
        });
    });

    return (
        <input ref={inputRef} type="text" className="form-control" name="daterange" placeholder="Please select..."/>
    );
}