import {useAuth} from "../common/Auth";
import {createRef, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {Loading} from "../component/Loading/Loading";
import {AuthPage} from "../component/AuthPage";
import {CreateLogger} from "../common/Logger";

export function LoginPage() {
    const logger = CreateLogger('LoginPage');
    const navigate = useNavigate();
    const location = useLocation();

    const auth = useAuth();
    const inputUsername = createRef();
    const inputPassword = createRef();
    const inputRememberMe = createRef();

    const [isLoading, setIsLoading] = useState(false);
    const [messageError, setMessageError] = useState("");

    if (!auth.isReady) return <AuthPage><Loading /></AuthPage>;
    if (auth.user) return <Navigate to="/dashboard" />;

    function attemptLogin() {
        setIsLoading(true);
        const username = inputUsername.current.value;
        const password = inputPassword.current.value;
        const remeberme = inputRememberMe.current.checked;

        logger.info("Attempt Login", username, '********', remeberme);
        setIsLoading(true);
        auth.login(username, password, remeberme).then(() => {
            // TODO: Navigate to the page the user wanted!
            logger.info("Successfully Logged in.");
            navigate(location.state && location.state.from ? location.state.from : '/dashboard', {
                replace: true
            });
        }, err => {
            logger.error("Login Failed", err);
            setMessageError(err.message || "Login Failed");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <AuthPage withLogo={true}>
            {isLoading ? <Loading message={"Logging In..."}/>
                : <div>
                    {messageError ? <p style={{color: 'red'}}>{messageError}</p> : ""}
                    <div className="user">
                        <div className="form-group">
                            <input ref={inputUsername} type="email"
                                   className="form-control form-control-user"
                                   placeholder="Username"/>
                        </div>
                        <div className="form-group">
                            <input ref={inputPassword} type="password"
                                   className="form-control form-control-user"
                                   placeholder="Password"/>
                        </div>
                        <div className="form-group">
                            <div className="custom-control custom-checkbox small">
                                <input ref={inputRememberMe} type="checkbox"
                                       className="custom-control-input" id="customCheck"
                                       defaultChecked={true}/>
                                <label className="custom-control-label"
                                       htmlFor="customCheck">Remember Me</label>
                            </div>
                        </div>
                        <a href="/" className="btn btn-primary btn-user btn-block"
                           onClick={e => {
                               e.preventDefault();
                               attemptLogin();
                           }}>Login</a>
                    </div>
                    <hr/>
                    <div className="text-center">
                        <a className="small" href="/forgot-password" onClick={(e) => {
                            e.preventDefault();
                            navigate('/forgot-password');
                        }}>Forgot Password?</a>
                    </div>
                </div>
            }
        </AuthPage>
    );
}