import {MapboxGLButtonControl} from "../MapControlButton";
import './MapProjectControl.scss';

export class MapProjectControl extends MapboxGLButtonControl {
    constructor({onClick}) {
        super({
            className: 'mapbox-gl-project-control',
            title: "Select Projects"
        });
        this.onClick = onClick;
    }

    handleClick(e) {
        if (this.onClick) this.onClick(e);
    }
}