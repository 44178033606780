import {Model, Q} from "@nozbe/watermelondb";
import {date, lazy, readonly, text, field, writer} from "@nozbe/watermelondb/decorators";

export class ProjectModel extends Model {
    static table = "project";
    static associations = {
        account_project: { type: 'has_many', foreignKey: 'project_id' },
    }

    @text('name') name;
    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt
    @field('locked') locked;

    @field('delete_locked') cannotDelete;

    @lazy accounts = this.collections.get('account').query(Q.on('account_project', 'project_id', this.id));


    @writer async lock() {
        await this.update(update => {
            update.locked = true;
        });
    }

    @writer async unlock() {
        await this.update(update => {
            update.locked = false;
        });
    }

    @writer async delete() {
        await this.markAsDeleted();
    }
}
