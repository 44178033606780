import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import _ from "lodash";
import {MapView} from "../Map/MapView";
import {DocumentPageContent, DocumentPageFooter, DocumentPageHeader} from "../Printable/PrintableDocument";
import {PrintablePage} from "../Printable/PrintablePage";
import {useEffect, useState} from "react";
import TrapMasterLogo from "../../assets/logo/top_logo_words_white.png";
import CompanyLogo from "../../assets/logo/OnTargetPestControlLogo.png";
import {BlockHighlight} from "../Map/BlockHighlight";
import {CreateLogger} from "../../common/logger/Logger";
import {MarkerLayer} from "../Map/Layers/MarkerLayer"; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from '@turf/turf'
import {database} from "../../common/DB";
import {Q} from "@nozbe/watermelondb";
import {GeoJson} from "../../common/map";
import {PageExpander} from "../Printable/PageExpander";
import {GridSettings, HeatMap} from "../Map/HeatMap";
import {randomInt, randomstr} from "../../common/Util";
import {EventTypes} from "../../config/EventTypes";

import './ProjectReport.scss';

const logger = CreateLogger('ProjectReport');

function ReportDocumentPage({children, noHeader, noFooter, reportName}) {
    let pageclass = [];
    if (noHeader === true) pageclass.push('no-header');
    if (noFooter === true) pageclass.push('no-footer');
    return (
        <PrintablePage pageClass={pageclass}>
            {noHeader !== true && <DocumentPageHeader>
                <div style={{float: 'left', height: 50, display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                    <img style={{
                        height: '40px',
                    }} src={CompanyLogo} />
                </div>

                <div style={{float: 'right', height: 50, display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                    <span>{reportName}</span>
                </div>
            </DocumentPageHeader>}
            <DocumentPageContent>
                {children}
            </DocumentPageContent>
            {noFooter !== true && <DocumentPageFooter>
                <div style={{float: 'right', height: 50, display: 'flex', justifyContent: 'center', alignItems: "center"}}>

                </div>
            </DocumentPageFooter>}
        </PrintablePage>
    );
}

function PageWrapper({children, printable, noHeader, noFooter, reportName}) {
    if (printable) return <ReportDocumentPage noHeader={noHeader} noFooter={noFooter} reportName={reportName}>{children}</ReportDocumentPage>;

    return <div style={{
        marginTop: 30,
    }}>{children}</div>
}

export function ProjectReport({project, dates, geoBounds, companyName, comments, reportName, printable}) {
    const [markers, setMarkers] = useState([]);
    const [markerMap, setMarkerMap] = useState(new Map());
    const [events, setEvents] = useState([]);
    const [geojson, setGeoJson] = useState({});
    const [HeatMapEvents, setHeatMapEvents] = useState([]);
    const [DailyStats, setDailyStats] = useState(new Map());
    const [TotalStats, setTotalStats] = useState({});

    const pages = [];

    const poly = geoBounds.features[0];
    const bounds = turf.bbox(geoBounds);

    // Fetch all markers within bounds.
    useEffect(() => {
        const subscription = database.get('marker').query(
            Q.where('project_id', project),
            Q.where('created_at', Q.gte(dates.startDate.getTime())),
            Q.where('created_at', Q.lt(dates.endDate.getTime()))
        ).observe().subscribe(frame => {
            //TODO: Use a geospacial index and query...
            const MarkerMap = new Map();
            const contained = _.filter(frame, marker => {
                let pos = turf.point([marker.longitude, marker.latitude]);
                if (turf.booleanContains(poly, pos)) {
                    MarkerMap.set(marker.id, marker);
                    return true;
                }
                return false;
            });

            setMarkers(contained);
            setMarkerMap(MarkerMap);
            setGeoJson(GeoJson(contained));
        });

        return () => subscription.unsubscribe();
    }, [geoBounds, project, dates]);

    useEffect(() => {
        const MarkerIDs = Array.from(markerMap.keys());

        //TODO: Use geospacial query to fetch non-marker related events!
        const subscription = database.get('event').query(
            Q.where('project_id', project),
            Q.where('created_at', Q.gte(dates.startDate.getTime())),
            Q.where('created_at', Q.lt(dates.endDate.getTime())),
            Q.where('marker_id', Q.oneOf(MarkerIDs))
        ).observe().subscribe(frame => {
            setEvents(frame);

            // Calculate HeatMap Events.
            setHeatMapEvents(_.map(frame, event => {
                const marker = markerMap.get(event.markerID);
                const longitude = event.longitude || marker.longitude;
                const latitude = event.latitude || marker.latitude;
                return {longitude, latitude, type: event.type};
            }));

            // Calculate daily event stats.
            setDailyStats(_.reduce(frame, (statsmap, event) => {
                const unixday = Math.floor(event.createdAt.getTime() / 86400000) * 86400000;
                let dailystats = statsmap.has(unixday) ? statsmap.get(unixday) : {};
                if (dailystats.hasOwnProperty(event.type)) dailystats[event.type] += 1;
                else dailystats[event.type] = 1;

                statsmap.set(unixday, dailystats);
                return statsmap;
            }, new Map()));

            setTotalStats(_.reduce(frame, (stats, event) => {
                if (stats.hasOwnProperty(event.type)) stats[event.type] += 1;
                else stats[event.type] = 1;
                return stats;
            }, {}));
        });

        return () => subscription.unsubscribe();
    }, [markerMap, dates, project]);

    if (printable) pages.push(
        <PageWrapper key="CoverPage" printable={printable} noHeader={true} noFooter={true}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: '100%'
            }}>
                <div style={{
                    textAlign: "center"
                }}>
                    <img style={{
                        width: '60%',
                    }} src={CompanyLogo} />
                    <div style={{marginTop: 20}}>
                        <table className="report-details" style={{margin: "auto", width: '60%'}} border={1}>
                            <tr>
                                <th>Title:</th>
                                <td>{reportName}</td>
                            </tr>
                            <tr>
                                <th>Company:</th>
                                <td>{companyName}</td>
                            </tr>
                            <tr>
                                <th>Date:</th>
                                <td>{(new Date()).toLocaleDateString()}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );

    pages.push(<PageWrapper key="ReportSummary" printable={printable} reportName={reportName}>
        <h4>Summary:</h4>
        <div style={{width: '100%'}}>
            <table className="report-details" style={{margin: "auto", width: '100%'}} border={1}>
                <tr>
                    <th>Organisation:</th>
                    <td>{companyName}</td>
                </tr>
                <tr>
                    <th>Date Start:</th>
                    <td>{dates.startDate.toLocaleDateString()}</td>
                </tr>
                <tr>
                    <th>Date Finished:</th>
                    <td>{dates.endDate.toLocaleDateString()}</td>
                </tr>
                <tr>
                    <th>Total Caught:</th>
                    <td>{TotalStats[EventTypes.TRAP_CATCH] || 0}</td>
                </tr>
                <tr>
                    <th>Total Sprung:</th>
                    <td>{TotalStats[EventTypes.TRAP_SPRUNG] || 0}</td>
                </tr>
                <tr>
                    <th>Comments:</th>
                    <td>{comments}</td>
                </tr>
            </table>
        </div>
    </PageWrapper>);

    if (DailyStats) {
        const rows = [];
        Array.from(DailyStats.keys()).sort().map(unixday => {
            let day = new Date(unixday);
            const daystats = DailyStats.get(unixday);
            rows.push([day.toLocaleDateString(), daystats[EventTypes.TRAP_CATCH] || 0, daystats[EventTypes.TRAP_SPRUNG] || 0]);
        });

        pages.push(PageExpander({
            printable: printable,
            numFirstPage: 32,
            numPerPage: 34,
            items: rows,
            wrapper: (rowset, rowidx, isFirst) => {
                return (
                    <PageWrapper key={"page_table_" + rowidx} printable={printable} reportName={reportName}>
                        {isFirst && <h4>Daily Statistics</h4>}
                        <table className="report-page-daily-stats">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Caught</th>
                                <th>Sprung</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rowset.map(row => {
                                return <tr key={randomstr(32)}>
                                    <th>{row[0]}</th>
                                    <td>{row[1]}</td>
                                    <td>{row[2]}</td>
                                </tr>;
                            })}
                            </tbody>
                        </table>
                    </PageWrapper>
                );
            }
        }));
    }

    pages.push(
        <PageWrapper key="BoundaryMap" printable={printable} reportName={reportName}>
            <h4>Traps Placed</h4>
            <div style={{width: '100%', height: printable ? '100%' : 600}}>
                <MapView
                    bounds={bounds}
                    interactive={!printable}
                    animate={false}
                >
                    <BlockHighlight bounds={geoBounds} />
                    <MarkerLayer geojson={geojson} />
                </MapView>
            </div>
        </PageWrapper>,
        <PageWrapper key="HeatMap" printable={printable} reportName={reportName}>
            <h4>Caught per 250M</h4>
            <div style={{width: '100%', height: printable ? '100%' : 600}}>
                <MapView
                    bounds={bounds}
                    interactive={!printable}
                    animate={false}
                >
                    <HeatMap
                        events={HeatMapEvents}
                        bounds={poly}
                        gridSettings={GridSettings.M250}
                        type="caught"
                    />
                    <MarkerLayer geojson={geojson} />
                </MapView>
            </div>
        </PageWrapper>
    );

    if (printable) pages.push(
        <PageWrapper key="ClosingPage" printable={printable} noHeader={true} noFooter={true}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: '100%'
            }}>
                <div style={{
                    textAlign: "center"
                }}>
                    <img style={{
                        width: '60%',
                    }} src={TrapMasterLogo} />
                    <h4>Created with TrapMaster</h4>
                    <h5>www.trapmaster.co.nz</h5>
                </div>
            </div>
        </PageWrapper>
    );

    return (
        <div>
            {pages}
        </div>
    );
}

