import {Model} from "@nozbe/watermelondb";
import {date, readonly, text, field, immutableRelation, writer} from "@nozbe/watermelondb/decorators";

export class EventModel extends Model {
    static table = "event";

    @field('type') type;
    @field('latitude') latitude;
    @field('longitude') longitude;
    @field('project_id') projectID;

    @field('marker_id') markerID;

    @field('triggered_by') userID;

    @immutableRelation('marker', 'marker_id') marker;
    @immutableRelation('project', 'project_id') project;
    @immutableRelation('account', 'triggered_by') createdBy;

    @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @writer async delete() {
        await this.markAsDeleted();
    }
}
