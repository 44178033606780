import {PageHeader} from "../component/PageHeader";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BasicCard} from "../component/Card/BasicCard";
import {useEffect, useRef, useState} from "react";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {DataTable} from "../component/DataTable";
import {useGlobalDialogs} from "../component/Dialog/GlobalDialogs";
import {BasicModal} from "../component/Dialog/BasicModal";
import {renderDate, Sleep, time} from "../common/Util";
import {TextInputWithIcon} from "../component/Form/TextInputWithIcon";
import {ButtonWithTooltip} from "../component/Button/ButtonWithTooltip";
import {EventTypes} from "../config/EventTypes";
import {Q} from "@nozbe/watermelondb";
import {parseInt} from "lodash";
import {EventManager} from "../common/data/EventManager";

export function EventsPage({since, limit, type, markerID, projectID}) {
    const database = useDatabase();
    const [isLoading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [searchParams] = useSearchParams();

    if (searchParams.has('limit')) limit = parseInt(searchParams.get('limit'));
    if (!limit || limit > 1000) limit = 1000;

    if (searchParams.has('type')) type = parseInt(searchParams.get('type'));
    if (searchParams.has('marker')) markerID = searchParams.get('marker');
    if (searchParams.has('project')) projectID = searchParams.get('project');
    if (searchParams.has('since')) since = parseInt(searchParams.get('since'));

    // Load in my projects...
    useEffect(() => {
        setLoading(true);
        const conditions = [Q.sortBy('created_at', Q.desc)];
        if (type) conditions.push(Q.where('type', type));
        if (since) conditions.push(Q.where('created_at', Q.gte(since)));
        if (limit) conditions.push(Q.take(limit));
        if (markerID) conditions.push(Q.where('marker_id', markerID));
        if (projectID) conditions.push(Q.where('project_id', projectID));

        const subscription = database.get('event').query(...conditions).observeWithColumns([]).subscribe(frame => {
            setEvents(frame);
            setLoading(false);
        });
        return () => subscription.unsubscribe();
    }, [database]);

    return <div>
        <PageHeader title="Events"/>
        <div className="row">
            <div className="col-12">
                <BasicCard
                    isLoading={isLoading}
                    loadingMessage="Loading Events..."
                >
                    {events.length ? <EventsViewer events={events} isLoading={isLoading}/> : <div>No Events Found...</div>}
                </BasicCard>
            </div>
        </div>
    </div>
}

const EventRender = Object.freeze({
    [EventTypes.MISC]: {
        text: "Unknown Event",
        icon: "fa fa-question"
    },
    [EventTypes.TRAP_PLACED]: {
        text: "Trap Placed",
        icon: "fa fa-map-marked"
    },
    [EventTypes.TRAP_CATCH]: {
        text: "Trap Catch",
        icon: "fa fa-check"
    },
    [EventTypes.TRAP_SPRUNG]: {
        text: "Trap Sprung",
        icon: "fa fa-skull"
    },
    [EventTypes.TRAP_PICKUP]: {
        text: "Trap Removed",
        icon: "fa fa-arrow-up"
    }
});

function EventLine({event, readOnly}) {
    console.log("CreatedAt: ", event.createdAt);
    let eventtype = event.type;
    if (!EventRender.hasOwnProperty(event.type)) eventtype = EventTypes.MISC;
    const renderer = EventRender[eventtype];

    return (
        <div className="timeline-item">
            <div className="timeline-item-marker">
                <div className="timeline-item-marker-text">{renderDate(event.createdAt)}</div>
                <div className="timeline-item-marker-indicator"><i className={renderer.icon}></i> </div>
            </div>
            <div className="timeline-item-content">
                <span>{renderer.text}</span>
                <span className="actions">
                    <button className="btn btn-circle" onClick={async () => {
                        await EventManager.deleteEvent(event.id);
                    }}><i className="fa fa-trash"></i></button>
                </span>
            </div>
        </div>
    );
}

export function EventsViewer({events, readOnly}) {
    return (
        <div className="timeline timeline-sm">
            {events.map(event => <EventLine key={event.id} event={event}/>)}
        </div>
    );
}