import {createContext, useContext, useEffect, useState} from "react";
import {Loading} from "../component/Loading/Loading";
import {useAuth} from "./Auth";
import {checkForUpdates, getLastPulledRevision, sync, SyncIfRequired} from "./DB";
import {AuthPage} from "../component/AuthPage";
import {Outlet} from "react-router-dom";
import {CreateLogger} from "./logger/Logger";

const logger = CreateLogger('SyncManager');

const syncContext = createContext({
    isReady: false
});

export function useSync() {
    return useContext(syncContext);
}

export function SyncProvider({children}) {
    const sync = PrepareSyncContext();
    return <syncContext.Provider value={sync}>{children}</syncContext.Provider>;
}

export function WaitForSync({children}) {
    const sync = useSync();
    if (!sync.isReady) return <AuthPage withLogo={true}><Loading message="Syncing Changes..." /></AuthPage>;
    return children ? <>{children}</> : <Outlet/>;
}

export function PrepareSyncContext() {
    const auth = useAuth();
    let [isReady, setIsReady] = useState(false);
    let [isSyncing, setIsSyncing] = useState(false);
    const [localRevision, setLocalRevision] = useState(null);

    // Sync local database with remote database.
    async function syncDB() {
        setIsSyncing(true);
        let success = false;
        try {
            const syncRequired = await checkForUpdates(auth.account.id);
            if (syncRequired) {
                logger.log("Syncing account: " + auth.account.id);
                await sync(auth.account.id);
                logger.log("Sync Finished!");
            }
            else logger.log("No Sync Required");

            const localRevisionFetched = await getLastPulledRevision();
            await setLocalRevision(localRevisionFetched);
            success = true;
        }
        catch (err) {
            logger.error("Failed to sync with remote database...", err);
        }

        setIsSyncing(false);
        return success;
    }

    useEffect(() => {

        // Fire off an initial sync to get up to date.
        syncDB().finally(() => {
            setIsReady(true);
        });

        let syncInterval;
        syncInterval = setInterval(async () => {
            await syncDB();
        }, 60000);

        const pageCloseListener = async function(event) {
            await syncDB();
            event.preventDefault();
            event.returnValue = false; // Seems required for Chrome
            return false;
        };

        // Register page close listener.
        // window.addEventListener("beforeunload", pageCloseListener, false);

        return () => {
            if (syncInterval) clearInterval(syncInterval);
            window.removeEventListener("beforeunload", pageCloseListener);
        };
    }, []);

    return { isReady, isSyncing, syncDB, localRevision};
}