import {createContext, useContext, useEffect, useState} from "react";
import {useLocation, Navigate, Outlet, useNavigate} from "react-router-dom";
import {FireAuth, FireStore} from "../config/firebase";
import {Loading} from "../component/Loading/Loading";
import firebase from "firebase";
import {AuthPage} from "../component/AuthPage";
import {CreateLogger} from "./Logger";

const logger = CreateLogger('Auth');

const authContext = createContext({
    isReady: false
});

window.auth = authContext;

export function useAuth() {
    return useContext(authContext);
}

export function AuthProvider({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function RequireAuth({children}) {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.isReady) return <AuthPage withLogo={true}><Loading message="Loading Session..." /></AuthPage>;
    if (!auth.user) return <Navigate to="/login" state={{from: location}} />
    return children ? <>{children}</> : <Outlet/>;
}

function useProvideAuth() {
    const [auth, setAuth] = useState(null);
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        function reset() {
            setAuth(null);
            setUser(null);
        }

        let userListener = null;
        let accountSubscription = null;
        const authStateListener = FireAuth.onAuthStateChanged(auth => {
            setIsReady(false);
            logger.info("FireBase Auth State Changed!", auth);
            if (auth) {
                setAuth(auth);

                // Load up user account data.
                if (userListener) userListener();
                userListener = FireStore.collection('users').doc(auth.uid).onSnapshot(userDoc => {
                    setUser(userDoc);
                    if (accountSubscription) accountSubscription();
                    accountSubscription = userDoc.get('account').onSnapshot(accountDoc => {
                        setAccount(accountDoc);
                        setIsReady(true);
                    });
                }, (err) => {
                    logger.error("Failed to fetch user document.");
                    reset();
                });
            }
            else {
                reset();
                setIsReady(true);
            }
        });

        return () => {
            authStateListener();
            if (userListener) userListener();
            if (accountSubscription) accountSubscription();
        }
    }, []);

    async function login(username, password, rememberme) {
        logger.info("User Attempt Login.");

        try {
            await FireAuth.setPersistence(rememberme
                ? firebase.auth.Auth.Persistence.LOCAL
                : firebase.auth.Auth.Persistence.SESSION
            );
        }
        catch (err) {
            if (!rememberme) throw new Error("Failed to setup session persistence.");
            logger.error("Failed to set session persistence.");
        }

        return await FireAuth.signInWithEmailAndPassword(username, password);
    }

    async function logout() {
        logger.info("User Attempt Logout.");
        return await FireAuth.signOut();
    }

    async function resetPassword(email) {
        return await FireAuth.sendPasswordResetEmail(email);
    }

    window.auth_account = account;

    return {
        isReady,
        auth,
        user,
        account,
        login,
        logout,
        resetPassword
    };
}
