import {useGlobals} from "../common/Globals";
import {Loading} from "./Loading/Loading";

export function GlobalLoader() {
    const globals = useGlobals();

    return globals.isLoading ? <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10000,
        backgroundColor: 'grey',
        color: 'white',
        textAlign: 'center',
    }}><Loading/></div> : null;
}