import './Loading.scss';

export function Loading({message}) {
    return (
        <div className="loading-container">
            <div className="loading-wrapper">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <p>{message || "Loading..."}</p>
            </div>
        </div>
    );
}