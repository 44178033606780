
export class LoadingManager {
    states = new Map();

    set(context, state) {
        if (typeof context === 'boolean') context = '**global';

        if (state) {
            this.states.set(context, true);
        }
        else if (this.states.has(context)) {
            this.states.delete(context);
        }
    }

    clear() {
        this.states.clear();
    }

    isLoading() {
        return !!this.states.size;
    }
}