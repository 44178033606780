import React, {useState} from "react";

export function SimpleWizard({stages, stageProps, finishedText, finishedIcon, onComplete}) {
    let [stage, setStage] = useState(0);

    let beforeNext = null;
    let beforePrev = null;

    function nextStage() {
        if (stage >= stages.length) return false;
        if (beforeNext && !beforeNext()) return false;
        beforeNext = beforePrev = null;
        setStage(stage + 1);
    }

    function prevStage() {
        if (stage <= 0) return false;
        if (beforePrev && !beforePrev()) return false;
        beforeNext = beforePrev = null;
        setStage(stage - 1);
    }

    if (stage < 0 || stage >= stages.length) throw new Error("Invalid Stage.");
    let stage_content = React.createElement(stages[stage], {
        ...stageProps,
        beforeNext: (callback) => {
            beforeNext = callback;
        },
        beforePrev: (callback) => {
            beforeNext = callback;
        },
    });

    if (!stage_content) throw new Error("Stage content not set...");

    return (
        <div>
            {stage_content}
            <div className="row mb-2">
                {stage > 0
                    ? <div className="col-6">
                        <button className="btn btn-danger btn-icon-split" disabled={stage === 0} onClick={(e) => {
                            e.preventDefault();
                            prevStage();
                            return false;
                        }}>
                            <span className="icon text-white-50">
                                <i className="fas fa-chevron-left"/>
                            </span>
                            <span className="text">Back</span>
                        </button>
                    </div>

                    : <div className="col-6"/>
                }

                {stage < stages.length - 1
                    ? <div className="col-6">
                        <button className="btn btn-success btn-icon-split float-right" onClick={(e) => {
                            e.preventDefault();
                            nextStage();
                            return false;
                        }}>
                            <span className="text">Next</span>
                            <span className="icon text-white-50">
                                    <i className="fas fa-chevron-right" />
                                </span>
                        </button>
                    </div>
                    // Else
                    : <div className="col-6">
                        <button className="btn btn-success btn-icon-split float-right" onClick={(e) => {
                            e.preventDefault();
                            if (beforeNext && !beforeNext()) return false;
                            onComplete();
                            return false;
                        }}>
                            <span className="text">{finishedText || "Finish"}</span>
                            <span className="icon text-white-50">
                                    <i className={finishedIcon || "fas fa-check"} />
                                </span>
                        </button>
                    </div>
                }
            </div>
        </div>
    );

}