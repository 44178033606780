import './printable.scss';
import TrapMasterLogo from "../../assets/logo/top_logo_words_white.png";
import {BodySwapPortal, usePortalManager} from "../BodySwapPortal";

export function DocumentPageHeader({children}) {
    return (
        <div className="page-header" style={{textAlign: "center"}}>
            {children}
        </div>
    );
}

export function DocumentPageFooter({children}) {
    return (
        <div className="page-footer">
            {children}
        </div>
    );
}

export function DocumentPageContent({children}) {
    return (
        <div className="page-content">
            {children}
        </div>
    );
}

export function DocumentViewer({children, onRequestClose, onOpened}) {
    const PortalManager = usePortalManager();

    return (
        <BodySwapPortal
            onRequestClose={onRequestClose}
            onOpened={onOpened}
        >
            <div className="printabledocument">
                <div className="printvieweronly" style={{height: 70, position: "absolute", top: 0, left: 0}}>
                    <div style={{
                        width: '100%', height: 70, position: "fixed",
                        backgroundColor: 'white',
                        zIndex: 9999,
                        boxShadow: "0 0 0.5cm rgba(0,0,0,0.5)",
                    }}>
                        <div style={{}}>
                            <img height={70} src={TrapMasterLogo}/>
                        </div>
                        <div style={{
                            float: "right",
                            height: '100%',
                            width: '200px',
                            marginRight: 10,
                            textAlign: "right"
                        }}>
                            <div style={{
                                margin: 0,
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)"
                            }}>
                                <button className="btn btn-danger btn-icon-split mr-1" onClick={() => {
                                    PortalManager.close();
                                }}>
                                    <span className="text">Close</span>
                                    <span className="icon text-white-50">
                                        <i className="fas fa-arrow-left"/>
                                    </span>
                                </button>
                                <button className="btn btn-success btn-icon-split" onClick={() => {
                                    window.print();
                                }}>
                                    <span className="text">Print</span>
                                    <span className="icon text-white-50">
                                        <i className="fas fa-print"/>
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="printvieweronly" style={{height: 70, marginTop: 70}}></div>
                {children}
                <div className="printvieweronly" style={{height: 50}}></div>
            </div>
        </BodySwapPortal>

    );
}