import {Routes, Route, Link, BrowserRouter, Outlet, Navigate} from "react-router-dom";
import DatabaseProviderComponent from "@nozbe/watermelondb/DatabaseProvider";
import {SiteContainer} from "./component/SiteContainer";
import {SitePage} from "./component/SitePage";
import {AuthProvider, RequireAuth} from "./common/Auth";
import {GlobalsProvider} from "./common/Globals";
import {GlobalLoader} from "./component/GlobalLoader";
import {DashboardPage} from "./page/DashboardPage";
import {MapPage} from "./page/MapPage";
import {ProjectsPage} from "./page/ProjectsPage";
import {UsersPage} from "./page/UsersPage";
import {SettingsPage} from "./page/SettingsPage";
import {LoginPage} from "./page/LoginPage";
import {SiteFullPage} from "./component/SiteFullPage";
import {ReportsPage} from "./page/Report/ReportsPage";
import {NewReportPage} from "./page/Report/NewReportPage";
import {ResetPasswordPage} from "./page/ResetPasswordPage";
import {database} from "./common/DB";
import {ReportPage} from "./page/Report/ReportPage";
import {EditReportPage} from "./page/Report/EditReportPage";
import {SyncProvider, WaitForSync} from "./common/Sync";
import {DevicesPage} from "./page/DevicesPage";
import {EventsPage} from "./page/EventsPage";
import {TestPage} from "./page/TestPage";
import {BodySwapPortal, BodySwapPortalProvider} from "./component/BodySwapPortal";
import {importData} from "./tools/import_data";
import OnTargetData from './data/ontarget.json';

window.data = {
    OnTarget: OnTargetData
};

window.tools = {
    importData
};

function App() {
  return (
      <BrowserRouter>
        <GlobalsProvider>
            <DatabaseProviderComponent database={database}>
                <AuthProvider>
                <GlobalLoader />
                    <BodySwapPortalProvider>
                        <Routes>
                        <Route path="/" exact={true} element={<Navigate to="/dashboard" />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/forgot-password" element={<ResetPasswordPage />} />

                        <Route element={<RequireAuth><SyncProvider><WaitForSync><Outlet/></WaitForSync></SyncProvider></RequireAuth>}>
                            <Route element={<SiteContainer><SitePage><Outlet/></SitePage></SiteContainer>}>
                                <Route path="/dashboard" element={<DashboardPage />} />

                                <Route path="/reports" element={<ReportsPage />} />
                                <Route path="/reports/new" element={<NewReportPage />} />
                                <Route path="/reports/:reportID" element={<ReportPage />} />
                                <Route path="/reports/:reportID/edit" element={<EditReportPage />} />

                                <Route path="/projects" element={<ProjectsPage />} />

                                <Route path="/devices" element={<DevicesPage />} />
                                <Route path="/events" element={<EventsPage />} />

                                <Route path="/users" element={<UsersPage />} />
                                <Route path="/settings" element={<SettingsPage />} />
                                <Route path="/test" element={<TestPage />} />
                                <Route path="/blank" element={<div>Blank Page!</div>} />
                            </Route>

                            <Route element={<SiteContainer renderFooter={false}><SiteFullPage><Outlet/></SiteFullPage></SiteContainer>}>
                                <Route path="/map" element={<MapPage />} />
                            </Route>
                        </Route>
                    </Routes>
                    </BodySwapPortalProvider>
              </AuthProvider>
            </DatabaseProviderComponent>
        </GlobalsProvider>
      </BrowserRouter>
  );
}

export default App;
