export function PageExpander({numFirstPage, numPerPage, items, wrapper, printable}) {
    const pages = [];
    if (!printable) return [wrapper(items, 0, true)];

    let i = 0;
    let idx = 0;
    while (i < items.length) {
        if (i === 0) {
            pages.push(wrapper(items.slice(i, i + numFirstPage), idx, true));
            i += numFirstPage;
        }
        else {
            pages.push(wrapper(items.slice(i, i + numPerPage), idx, false));
            i += numPerPage;
        }
        idx++;
    }
    return pages;
}