import {Model} from "@nozbe/watermelondb";
import {date, readonly, text, field, immutableRelation, writer, json} from "@nozbe/watermelondb/decorators";

export class DeviceConfigModel extends Model {
    static table = "device_config";

    @field('device_id') device_id;
    @immutableRelation('device', 'device_id') device;

    @json('config', json => json) config;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @writer async delete() {
        await this.markAsDeleted();
    }
}
