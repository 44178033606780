export class Util {

}

export function Sleep(ms) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}


export function QueryFirstSubscribe(Query, onFirst, onUpdate) {
    let first = true;
    return Query.subscribe(async frame => {
        onUpdate(frame);
        if (first) {
            onFirst(frame);
            first = false;
        }
    });
}

export function renderDate(date) {
    return date.toLocaleString();
}

export function unixtime() {
    return Math.floor(Date.now() / 1000);
}

export function randomstr(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}