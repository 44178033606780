import {useEffect, useMemo, useRef, useState} from "react";
const $ = window.$;

export function DataTable({options, children}) {
    const container = useRef();

    // useEffect(() => {
    //     console.info("EFFECT CALLED!");
    //     const instance = $(container.current).DataTable(options);
    //     instance.rows().invalidate('dom');
    //     instance.draw();
    // });

    return (
        <div className="table-responsive">
            <table ref={container} className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                {children}
            </table>
        </div>
    );
}