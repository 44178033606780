import {Subject, map, tap} from "rxjs";
import _ from "lodash";

export class TimeFeed {

    constructor(query, timeKey, preferOlder) {
        this._query = query;
        this._timeKey = timeKey;
        this._preferOlder = preferOlder;

        if (!this._timeKey) throw new Error("A timekey is required!");

        this._cacheStart = null;
        this._cacheEnd = null;

        this._subscription = null;
        this._subject = new Subject();
    }

    query(start, end) {
        this._updateCache(start, end);
        const timeFilter = (doc) => {
            const docTime = doc.get(this._timeKey).seconds;
            if (end && docTime > end) return false;
            if (start && docTime < start) return false;
            return true;
        }

        return this._subject.pipe(
            map(docs => _.filter(docs, doc => timeFilter(doc))),
        );
    }

    _subscribeFrom() {
        if (this._subscription) this._subscription.unsubscribe();
        this._subscription = this._query.where(this._timeKey, '>=', new Date(this._cacheStart)).onSnapshot(rows => {
            console.log("Fetched Rows: ", rows);
            this._subject.next(rows.docs);
        });
    }

    _subscribeTill() {
        if (this._subscription) this._subscription.unsubscribe();
        this._subscription = this._query.where(this._timeKey, '<', new Date(this._cacheEnd)).onSnapshot(rows => {
            this._subject.next(rows.docs);
        });
    }

    _updateCache(start, end) {
        let updateRequired = false;
        if (start < this._cacheStart) {
            this._cacheStart = start;
            updateRequired = true;
        }

        if (end > this._cacheEnd) {
            this._cacheEnd = end;
            updateRequired = true;
        }

        if (updateRequired) {
            if (this._preferOlder) this._subscribeTill();
            else this._subscribeFrom();
        }
    }
}