import {PageHeader} from "../component/PageHeader";
import {useNavigate} from "react-router-dom";
import {BasicCard} from "../component/Card/BasicCard";
import {useEffect, useRef, useState} from "react";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {DataTable} from "../component/DataTable";
import {useGlobalDialogs} from "../component/Dialog/GlobalDialogs";
import {BasicModal} from "../component/Dialog/BasicModal";
import {renderDate, Sleep} from "../common/Util";
import {TextInputWithIcon} from "../component/Form/TextInputWithIcon";
import {ButtonWithTooltip} from "../component/Button/ButtonWithTooltip";
import {CreateLogger} from "../common/logger/Logger";
import withObservables from "@nozbe/with-observables";
import {database} from "../common/DB";

const logger = CreateLogger('DevicesPage');
export function DevicesPage() {
    const database = useDatabase();
    const navigate = useNavigate();
    const globalDialog = useGlobalDialogs();
    const [isLoading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);

    // Load in my projects...
    useEffect(() => {
        setLoading(true);
        const subscription = database.get('device').query().observeWithColumns(['status']).subscribe(devices => {
            setDevices(devices);
            setLoading(false);
        });
        return () => subscription.unsubscribe();
    }, [database]);

    return <div>
        <PageHeader title="My Devices"/>
        <div className="row">
            <div className="col-12">
                <BasicCard
                    isLoading={isLoading}
                    loadingMessage="Loading Devices..."
                >
                    <DevicesTable
                        devices={devices}
                        onRequestEdit={(device) => {
                            globalDialog.showDialog(<DeviceDetailsDialog
                                device={device}
                                onSave={() => globalDialog.closeDialog()}
                                onFinished={() => globalDialog.closeDialog()}
                            />);
                        }}

                        onRequestConfigEdit={(device) => {
                            globalDialog.showDialog(<EnhancedDeviceConfigDialog
                                device={device}
                                onSave={() => globalDialog.closeDialog()}
                                onFinished={() => globalDialog.closeDialog()}
                            />);
                        }}
                    />
                </BasicCard>
            </div>
        </div>
    </div>
}

function DeviceRow({device, status, config, onRequestEdit, onRequestConfigEdit}) {
    const latest = status.length ? status[0] : null;
    return (
        <tr key={device.id}>
            <td>{device.name}</td>
            <td>{device.deviceHash}</td>
            <td>{latest ? renderDate(latest.createdAt) : "Never"}</td>
            <td>
                <ButtonWithTooltip
                    className="btn btn-info float-right"
                    title="Edit Device"
                    onClick={() => onRequestEdit(device)}
                >
                    <i className="fas fa-pencil-alt" />
                </ButtonWithTooltip>
                <ButtonWithTooltip
                    className="btn btn-info float-right mr-1"
                    title="Edit Config"
                    onClick={() => onRequestConfigEdit(device)}
                >
                    <i className="fas fa-cogs" />
                </ButtonWithTooltip>
            </td>
        </tr>
    );
}

const enhance = withObservables(['device'], ({ device }) => ({
    device,
    status: device.status,
    config: device.config
}));
const EnhancedDeviceRow = enhance(DeviceRow);


function DevicesTable({devices, onRequestEdit, onRequestConfigEdit}) {
    return (
        <DataTable selector="#dataTable">
            <thead>
            <tr>
                <th>Name</th>
                <th>Hash</th>
                <th>Last Online</th>
                <th style={{width: '165px'}}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {devices.length
                ? devices.map(device => <EnhancedDeviceRow
                    key={device.id}
                    device={device}
                    onRequestEdit={onRequestEdit}
                    onRequestConfigEdit={onRequestConfigEdit}
                />)
                : <tr>
                    <td colSpan={4}>No Devices</td>
                </tr>
            }
            </tbody>
        </DataTable>
    );
}

function DeviceDetailsDialog({device, onSave, onFinished}) {
    const database = useDatabase();
    const [isSaving, setIsSaving] = useState(false);

    const inputDeviceName = useRef();

    async function saveChanges() {
        setIsSaving(true);
        try {
            await database.write(async () => {
                await device.update(update => {
                    update.name = inputDeviceName.current.value;
                });
            });
        } catch (err) {
            // TODO: Show alert message, etc.
            console.error("Failed to save device...");
        }
        finally {
            setIsSaving(false);
        }

        if (onSave) onSave();
    }

    return (
        <BasicModal
            title="Edit Device"
            onClose={() => onFinished()}
            isLoading={isSaving}
            loadingMessage={"Saving Device..."}
            buttons={<>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onFinished()}
                >Cancel</button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => saveChanges()}
                >Save Changes</button>
            </>}
        >
            <TextInputWithIcon
                inputRef={inputDeviceName}
                icon="fa fa-folder"
                title="Name"
                placeholder="Device Name..."
                defaultValue={device?.name}
            />
        </BasicModal>
    );
}

function DeviceConfigDialog({device, config, onSave, onFinished}) {
    const [isSaving, setIsSaving] = useState(false);
    const latest = config.length ? config[0] : null;
    const inputEnableLocationTracking = useRef();

    async function saveChanges() {
        setIsSaving(true);
        let newConfig;
        try {
            newConfig = JSON.parse(inputEnableLocationTracking.current.value);
        } catch (err) {
            console.warn("Failed to parse json.");
            alert("Bad JSON Payload");
            setIsSaving(false);
            return false;
        }


        try {
            await database.write(async () => {
                if (latest) {
                    // Save device config.
                    await latest.update(record => {
                        record.config = newConfig;
                    });
                }
                else {
                    // Create new device config..
                    await database.get('device_config').create(record => {
                        record._raw.id = device.id;
                        record.device_id = device.id;
                        record.config = newConfig;
                    });
                }
            });
        } catch (err) {
            // TODO: Show alert message, etc.
            console.error("Failed to save device config...");
        }
        finally {
            setIsSaving(false);
        }

        if (onSave) onSave();
    }

    return (
        <BasicModal
            title="Edit Device Config"
            onClose={() => onFinished()}
            isLoading={isSaving}
            loadingMessage={"Saving Config..."}
            buttons={<>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onFinished()}
                >Cancel</button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => saveChanges()}
                >Save Changes</button>
            </>}
        >
            <TextInputWithIcon
                inputRef={inputEnableLocationTracking}
                icon="fa fa-folder"
                title="Location Tracking"
                placeholder="Location Tracking"
                defaultValue={JSON.stringify(latest?.config)}
            />
        </BasicModal>
    );
}

const enhanceConfigDialog = withObservables(['device'], ({ device }) => ({
    device,
    config: device.config
}));
const EnhancedDeviceConfigDialog = enhanceConfigDialog(DeviceConfigDialog);