import {useAuth} from "../common/Auth";
import {createRef, useState} from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {Loading} from "../component/Loading/Loading";
import {AuthPage} from "../component/AuthPage";
import {CreateLogger} from "../common/Logger";

export function ResetPasswordPage() {
    const logger = CreateLogger('ResetPasswordPage');
    const navigate = useNavigate();

    const auth = useAuth();
    const inputUsername = createRef();

    const [isLoading, setIsLoading] = useState(false);
    const [messageError, setMessageError] = useState("");
    const [messageSuccess, setMessageSuccess] = useState("");

    if (!auth.isReady) return <AuthPage><Loading /></AuthPage>;
    if (auth.user) return <Navigate to="/dashboard" />;

    function sendPasswordResetEmail() {
        setMessageError("");
        setMessageSuccess("");
        const email = inputUsername.current.value;
        setIsLoading(true);
        auth.resetPassword(email).then(() => {
            setMessageSuccess("A reset email has been sent, please check your emails.");
        }, err => {
            logger.error("Failed to send reset password email.", err);
            setMessageError(err.message || "Failed to send reset email.");
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <AuthPage>
            {isLoading ? <Loading message={"Sending Password Reset Email..."} />
                : <div>
                    <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                        <p className="mb-4">We get it, stuff happens. Just enter your email address
                            below
                            and we'll send you a link to reset your password!</p>
                    </div>
                    <div className="user">
                        <div className="form-group">
                            <input
                                ref={inputUsername}
                                type="email"
                                className="form-control form-control-user"
                                placeholder="Enter Email Address..." />
                        </div>
                        {messageError ? <p style={{color: 'red'}}>{messageError}</p> : ""}
                        {messageSuccess ? <p style={{color: 'green'}}>{messageSuccess}</p> : ""}
                        <button className="btn btn-primary btn-user btn-block" onClick={() => {
                            sendPasswordResetEmail();
                        }}>
                            Reset Password
                        </button>
                    </div>
                    <hr/>
                    <div className="text-center">
                        <a className="small" href="/login" onClick={(e) => {
                            e.preventDefault();
                            navigate('/login');
                        }}>Back</a>
                    </div>
                </div>}
        </AuthPage>
    );
}