import {appSchema, tableSchema} from "@nozbe/watermelondb";

export default appSchema({
    version: 205,
    tables: [
        tableSchema({
            name: 'user',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'last_name', type: 'string' },
                { name: 'account_id', type: 'string', isIndexed: true },
                { name: 'email', type: 'string' },
                { name: 'active', type: 'boolean' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'project',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'locked', type: 'boolean' },
                { name: 'delete_locked', type: 'boolean' },

                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'account',
            columns: [
                { name: 'name', type: 'string' },

                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'account_project',
            columns: [
                { name: 'account_id', type: 'string', isIndexed: true },
                { name: 'project_id', type: 'string', isIndexed: true },

                { name: 'created_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'marker',
            columns: [
                { name: 'project_id', type: 'string', isIndexed: true },
                { name: 'type', type: 'number', isIndexed: true },

                { name: 'latitude', type: 'number' },
                { name: 'longitude', type: 'number' },

                { name: 'created_by', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'removed_at', type: 'number', isOptional: true },
            ]
        }),
        tableSchema({
            name: 'event',
            columns: [
                { name: 'project_id', type: 'string', isIndexed: true },
                { name: 'type', type: 'number', isIndexed: true },
                { name: 'marker_id', type:'string', isIndexed: true, isOptional: true },

                /** Shouldn't be used if there is a marker associated **/
                { name: 'latitude', type: 'number', isOptional: true },
                { name: 'longitude', type: 'number', isOptional: true },

                { name: 'triggered_by', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'device',
            columns: [
                { name: 'account_id', type: 'string', isIndexed: true },
                { name: 'name', type: 'string' },
                { name: 'device_hash', type: 'string', isIndexed: true },

                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'device_status',
            columns: [
                { name: 'device_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string' },
                { name: 'timestamp', type: 'number' },

                { name: 'latitude', type: 'number' },
                { name: 'longitude', type: 'number' },

                /** Used to store device information, like battery, etc **/
                { name: 'info', type: 'string', isOptional: true },

                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'device_config',
            columns: [
                { name: 'device_id', type: 'string', isIndexed: true },

                /** Used to store device config **/
                { name: 'config', type: 'string', isOptional: true },

                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'report',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'project_id', type: 'string' },
                { name: 'company_name', type: 'string' },
                { name: 'options', type: 'string' },
                { name: 'date_start', type: 'number' },
                { name: 'date_end', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ]
        })
    ]
});
