import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

import MapboxPitchToggleControl from '@watergis/mapbox-gl-pitch-toggle-control';
import '@watergis/mapbox-gl-pitch-toggle-control/css/styles.css';

import React, {createContext, useContext, useEffect, useState} from "react";
import {MapStyleControl} from "./MapStyleControl/MapStyleControl";
import {BasicModal} from "../Dialog/BasicModal";
import {InputWithTitle} from "../Form/InputWithTitle";
import {InputGroupWithIcon} from "../Form/InputGroupWithIcon";
import {CreateLogger} from "../../common/logger/Logger";
import {MapProjectControl} from "./MapProjectControl/MapProjectControl";
import {CheckBoxCloud} from "../Form/CheckBoxCloud";
import {MarkerTypes} from "../../config/MarkerTypes";
import {Loading} from "../Loading/Loading";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {Q} from "@nozbe/watermelondb";

mapboxgl.accessToken = 'pk.eyJ1Ijoic3Vic2VydmVyIiwiYSI6ImNrd2FtdWoxbDJtajkyb3A4NHhkbndhejMifQ.5CAuO-OLAT7VZ9aSCIr0ww';

const logger = CreateLogger('MapView');

const MapContext = createContext(null);
export function useMap() {
    return useContext(MapContext);
}

export function MapProvider({mapInstance, children}) {
    return <MapContext.Provider value={mapInstance}>{children}</MapContext.Provider>
}

export class MapView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.mapContainer = React.createRef();
        this.map = React.createRef();
        this.state = {
            isLoaded: false,
            showStyleDialog: false,
            style: localStorage.mapStyle || 'mapbox://styles/subserver/ckwaq2ghl9qfb14qsd7jy8t6u'
        };
    }

    isInteractive() {
        return this.props.interactive === undefined || this.props.interactive;
    }

    componentDidMount() {
        if (this.map.current) return;
        this.map.current = new mapboxgl.Map({
            container: this.mapContainer.current,
            projection: 'globe',
            style: this.state.style,
            interactive: this.isInteractive(),
            ...this.props.mapSettings
        });

        if (this.props.bounds) {
            let boundsoptions = {padding: 50};
            if (this.props.animate === false) boundsoptions.duration = 0;
            this.map.current.fitBounds(this.props.bounds, boundsoptions);
        }

        if (this.isInteractive() && this.props.showLocationControl !== false) {
            this.map.current.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    trackUserLocation: true,
                    showUserHeading: false
                })
            );
        }

        if (this.props.showScale !== false) {
            this.map.current.addControl(new mapboxgl.ScaleControl({}));
        }

        if (this.isInteractive() && this.props.showNavigationControl !== false) {
            this.map.current.addControl(new mapboxgl.NavigationControl());
        }

        if (this.isInteractive() && this.props.showPitchControl) {
            this.map.current.addControl(new MapboxPitchToggleControl({minpitchzoom: 11}));
        }

        if (this.isInteractive() && this.props.showStyleControl) {
            this.map.current.addControl(new MapStyleControl({
                onClick: (e) => {
                    this.setState({showStyleDialog: !this.state.showStyleDialog});
                }
            }));
        }

        if (this.isInteractive() && this.props.showProjectControl) {
            this.map.current.addControl(new MapProjectControl({
                onClick: (e) => {
                    this.setState({showProjectDialog: !this.state.showProjectDialog});
                }
            }));
        }

        if (this.isInteractive() && this.props.showFullScreenControl) {
            this.map.current.addControl(new mapboxgl.FullscreenControl());
        }

        if (this.props.onInit) this.props.onInit(this.map.current);
        this.map.current.on('load', () => {
            this.setState({isLoaded: true});
            this.map.current.setFog({});
            if (this.props.onLoad) this.props.onLoad(this.map.current);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.style !== prevState.style) {
            // Style Updated, inform the map object.
            this.map.current.setStyle(this.state.style);
        }


    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%'}}>
                <div ref={this.mapContainer} className="map-container" style={{width: '100%', height: '100%'}}/>
                {this.state.isLoaded && <MapProvider mapInstance={this.map.current}><div>{this.props.children}</div></MapProvider>}
                <div>
                    {this.state.showStyleDialog && <LayerControlDialog
                        currentStyle={this.state.style}
                        setStyle={(newstyle) => this.setState({
                            style: newstyle
                        })}
                        onClose={() => this.setState({showStyleDialog: false})}
                        enabledFeatures={this.props.enabledFeatures}
                        setEnabledFeatures={this.props.setEnabledFeatures}
                        enabledProjects={this.props.enabledProjects}
                        setEnabledProjects={this.props.setEnabledProjects}
                    />}
                </div>
            </div>
        );
    }
}

function LayerControlDialog({
    onClose,
    currentStyle, setStyle,
    enabledFeatures, setEnabledFeatures,
    enabledProjects, setEnabledProjects
}) {
    const database = useDatabase();

    const [projects, setProjects] = useState(false);

    useEffect(() => {
        if (setEnabledProjects)
        {
            const subscription = database.get('project').query(Q.where('locked', false)).observeWithColumns(['name']).subscribe(projects => {
                setProjects(projects);
            });
            return () => subscription.unsubscribe();
        }
    }, [setEnabledProjects]);


    const sections = [];

    if (setEnabledFeatures) {
        const featureToggleHandler = (type, enabled) => {
            setEnabledFeatures(Object.assign({}, enabledFeatures, {
                [type]: !!enabled
            }));
        }

        sections.push(<div key="MarkerControls">
            <hr/>
            <h5>Markers</h5>
            <CheckBoxCloud name="Traps" checked={enabledFeatures[MarkerTypes.TRAP]} onChange={enabled => featureToggleHandler(MarkerTypes.TRAP, enabled)} />
            <CheckBoxCloud name="Bait Stations" checked={enabledFeatures[MarkerTypes.POISON]} onChange={enabled => featureToggleHandler(MarkerTypes.POISON, enabled)} />
            <CheckBoxCloud name="Catches" checked={enabledFeatures[MarkerTypes.CATCH]} onChange={enabled => featureToggleHandler(MarkerTypes.CATCH, enabled)} />
            <CheckBoxCloud name="Sightings" checked={enabledFeatures[MarkerTypes.SIGHTING]} onChange={enabled => featureToggleHandler(MarkerTypes.SIGHTING, enabled)} />
            <CheckBoxCloud name="Hazards" checked={enabledFeatures[MarkerTypes.HAZARD]} onChange={enabled => featureToggleHandler(MarkerTypes.HAZARD, enabled)} />
            <CheckBoxCloud name="Tasks" checked={enabledFeatures[MarkerTypes.TASK]} onChange={enabled => featureToggleHandler(MarkerTypes.TASK, enabled)} />
        </div>);
    }

    if (setEnabledProjects) {
        sections.push(
            <div key="ProjectControls">
                <hr/>
                <h5>Projects</h5>
                {projects === false
                    ? <Loading message="Loading Projects..."/>
                    : <ProjectCloud
                        enabledProjects={enabledProjects}
                        projects={projects}
                        onChanged={(projectID, enabled) => {
                            if (enabledProjects === false)
                            {
                                enabledProjects = projects.reduce((C, project) => {
                                    C[project.id] = true;
                                    return C;
                                }, {});
                            }

                            setEnabledProjects(Object.assign({}, enabledProjects, {
                                [projectID]: enabled
                            }));
                        }}
                    />
                }
            </div>
        );
    }

    return (
        <BasicModal
            showHeader={false}
            title="Map Settings"
            onClose={onClose}
        >
            <InputWithTitle title="Render Style">
                <InputGroupWithIcon icon="fa fa-eye">
                    <select className="form-control" value={currentStyle} onChange={(e) => {
                        const style = e.target.value;
                        localStorage.mapStyle = style;
                        setStyle(style);
                    }}>
                        <option value="mapbox://styles/subserver/ckwaq2ghl9qfb14qsd7jy8t6u">Satellite</option>
                        <option value="mapbox://styles/subserver/ckwj9gop12ef015qagx23mvkz">Satellite 3D</option>
                        <option value="mapbox://styles/subserver/ckwjbrt1d0jsp14qu802v6hhe">Topography</option>
                    </select>
                </InputGroupWithIcon>
            </InputWithTitle>
            {sections}
            <button
                type="button"
                className="col-12 btn btn-primary"
                onClick={onClose}
            >Done</button>
        </BasicModal>
    );
}


function ProjectCloud({enabledProjects, projects, onChanged}) {
    if (!Array.isArray(projects) || projects.length < 1) return <div>No Projects.</div>;
    return projects.map(project => {
        let isChecked = enabledProjects === false || enabledProjects[project.id];
        return <CheckBoxCloud name={project.name} checked={isChecked} onChange={enabled => onChanged(project.id, !!enabled)} />
    });
}