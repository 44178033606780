import {PageHeader} from "../../component/PageHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BasicCard} from "../../component/Card/BasicCard";
import {useEffect, useState} from "react";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {Sleep} from "../../common/Util";
import {InputWithTitle} from "../../component/Form/InputWithTitle";
import {InputGroupWithIcon} from "../../component/Form/InputGroupWithIcon";


export function EditReportPage() {
    const params = useParams();
    const database = useDatabase();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [report, setReport] = useState(null);

    const [reportName, setReportName] = useState("");
    const [reportCompanyName, setReportCompanyName] = useState("");
    const [reportComments, setReportComments] = useState("");

    useEffect(() => {
        // Load report.
        setLoading(true);
        const subscriber = database.get('report').findAndObserve(params.reportID).subscribe(async report => {
            setReport(report);
            setReportName(report.name);
            setReportCompanyName(report.companyName);
            setReportComments(report.options.comments);

            console.log(report);

            setLoading(false);
        })

        return () => subscriber.unsubscribe();
    }, []);

    return (
        <div>
            <PageHeader title="Edit Report">
                <div>
                    <button className="btn btn-success btn-icon-split mr-2" title="Edit this report" onClick={async () => {
                        await database.write(async () => {
                            await report.update(update => {
                                update.name = reportName;
                                update.companyName = reportCompanyName;
                                update.options = {
                                    ...report.options,
                                    comments: reportComments
                                }
                            })
                        });
                        navigate('/reports/' + params.reportID, { replace: true });
                    }}>
                        <span className="text">Save</span>
                        <span className="icon text-white-50">
                            <i className="fas fa-save"/>
                        </span>
                    </button>
                </div>

            </PageHeader>

            <div className="row">
                <div className="col-12">
                    <BasicCard
                        isLoading={isLoading}
                        loadingMessage="Loading Report Details..."
                    >
                        <InputWithTitle title="Report Title">
                            <InputGroupWithIcon icon="fa fa-clipboard">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Report title..."
                                    value={reportName}
                                    onChange={(e) => {
                                        setReportName(e.target.value);
                                    }}
                                />
                            </InputGroupWithIcon>
                        </InputWithTitle>

                        <InputWithTitle title="Company Name">
                            <InputGroupWithIcon icon="fa fa-building">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Company Name..."
                                    value={reportCompanyName}
                                    onChange={(e) => {
                                        setReportCompanyName(e.target.value);
                                    }}
                                />
                            </InputGroupWithIcon>
                        </InputWithTitle>

                        <InputWithTitle title="Report Comments">
                            <textarea
                                className="form-control"
                                id="reportCommentsInput"
                                placeholder="Anything you would like to mention?"
                                rows={5}
                                cols={30}
                                onChange={(e) => {
                                    setReportComments(e.target.value);
                                }}
                                value={reportComments}
                            />
                        </InputWithTitle>
                    </BasicCard>
                </div>
            </div>
        </div>
    );
}
