import {database} from "../DB";
import {CreateLogger} from "../logger/Logger";

const logger = CreateLogger('EventManager');
export class EventManager {
    static async deleteEvent(eventID) {
        await database.write(async () => {
            try {
                const event = await database.get('event').find(eventID);
                await event.markAsDeleted();
            } catch (err) {
                logger.warn("Failed to find event to delete.")
            }
        });
    }
}