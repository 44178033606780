import {useMap} from "./MapView";
import {useEffect, useMemo, useState} from "react";
import {randomstr} from "../../common/Util";
import _ from "lodash";
import {EventTypes} from "../../config/EventTypes";
import * as turf from '@turf/turf'
import {CreateLogger} from "../../common/logger/Logger";

const logger = CreateLogger('HeatMap');

export const GridSettings = Object.freeze({
    ACRE: {number: 63.615, units: 'meters'},
    KM: {number: 1, units: 'kilometers'},
    M100: {number: 100, units: 'meters'},
    M250: {number: 250, units: 'meters'},
    M500: {number: 500, units: 'meters'},
});

export function HeatMap({events, bounds, gridSettings}) {
    const map = useMap();
    const unique_id = useMemo(() => randomstr(32), []);
    const [HeatMapData, setHeatMapData] = useState({isReady: false});

    if (!gridSettings) gridSettings = GridSettings.ACRE;

    useEffect(() => {
        if (!events) return;
        try {
            // calculate heat map.
            let cellWidth = gridSettings.number;
            let bufferedBbox = turf.bbox(turf.buffer(bounds, cellWidth, {units: gridSettings.units}));
            let options = { units: gridSettings.units, mask: bounds};
            let heatMapGrid = turf.squareGrid(
                bufferedBbox,
                cellWidth,
                options
            );

            const clippedFeatures = [];
            const totalStats = {
                minCaught: 0,
                minSprung: 0,
                maxCaught: 0,
                maxSprung: 0
            };

            turf.featureEach(heatMapGrid, function (currentFeature, featureIndex) {
                let intersected = turf.intersect(bounds, currentFeature);
                if (intersected) {
                    // Cell exists within bounds.
                    clippedFeatures.push(intersected);

                    // Get all events within this feature.
                    const contained = _.filter(events, event => {
                        // logger.info("Scanning Event: ", event);
                        let pos = turf.point([event.longitude, event.latitude]);
                        return turf.booleanContains(intersected, pos);
                    });

                    let stats = {caught: 0, sprung: 0};
                    if (contained.length) {
                        if (contained.length) stats = _.reduce(contained, (stats, event) => {
                            switch (event.type) {
                                case EventTypes.TRAP_CATCH:
                                    stats.caught += 1;
                                    break;
                                case EventTypes.TRAP_SPRUNG:
                                    stats.sprung += 1;
                                    break;
                            }
                            return stats;
                        }, stats);

                        if (stats.caught < totalStats.minCaught) totalStats.minCaught = stats.caught;
                        if (stats.caught > totalStats.maxCaught) totalStats.maxCaught = stats.caught;
                        if (stats.sprung < totalStats.minSprung) totalStats.minSprung = stats.sprung;
                        if (stats.sprung > totalStats.maxSprung) totalStats.maxSprung = stats.sprung;
                    }

                    // Find events occurring within this cell.
                    intersected.properties.events = contained;
                    intersected.properties.eventCount = contained.length;
                    intersected.properties.caught = stats?.caught;
                    intersected.properties.sprung = stats?.sprung;
                }
            });

            setHeatMapData({
                isReady: true,
                stats: totalStats,
                grid: turf.featureCollection(clippedFeatures),
            });
        }
        catch (err) {
            logger.error("Failed to calculate HeatMap", err);
        }
    }, [events]);

    useEffect(() => {
        if (!HeatMapData.isReady) return;

        const sourceID = unique_id + '_heatmap_source';
        const layerID = unique_id + '_heatmap_layer';
        const outlineID = unique_id + '_heatmap_outline';

        const outlineSourceID = unique_id + '_outline_source';
        const outlineLayerID = unique_id + '_outline_layer';

        map.addSource(sourceID, {
            'type': 'geojson',
            'data': HeatMapData.grid
        });

        map.addSource(outlineSourceID, {
            'type': 'geojson',
            'data': bounds
        });

        let min = HeatMapData.stats.minCaught;
        let max = HeatMapData.stats.maxCaught;
        const colours = [min, 'rgba(255,255,255, 0.5)'];
        if (max > min) colours.push(max, 'rgba(255,0,0,1)');

        // Add a new layer to visualize the polygon.
        map.addLayer({
            'id': layerID,
            'type': 'fill',
            'source': sourceID,
            'layout': {},
            'paint': {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'caught'],
                    ...colours
                ],
                'fill-opacity': 0.5
            },
        });

        // Add a black outline around each heatmap feature.
        map.addLayer({
            'id': outlineID,
            'type': 'line',
            'source': sourceID,
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 1
            }
        });

        // Add a black outline around the entire bounds.
        map.addLayer({
            'id': outlineLayerID,
            'type': 'line',
            'source': outlineSourceID,
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 3
            }
        });

        return () => {
            map.removeLayer(outlineID);
            map.removeLayer(layerID);
            map.removeSource(sourceID);

            map.removeLayer(outlineLayerID);
            map.removeSource(outlineSourceID);
        }
    }, [map, HeatMapData]);

    useEffect(() => {
        const source = map.getSource('block_boundary');
        if (source) source.setData(bounds);
    }, [bounds]);

    return (<></>);
}