import {createContext, useContext, useState} from "react";
import {LoadingManager} from "./LoadingManager";

const GLOBALS = createContext({
    isReady: false,
});

export function useGlobals() {
    return useContext(GLOBALS);
}

export function GlobalsProvider({children}) {
    const globalContext = GlobalsContext();
    return <GLOBALS.Provider value={globalContext}>{children}</GLOBALS.Provider>
}

/** Prepare and maintain the global scope **/
const globalLoaders = new LoadingManager();
function GlobalsContext() {
    const [isLoading, setLoading] = useState(false);

    function globalLoadingController(context, state) {
        globalLoaders.set(context, state);
        setLoading(globalLoaders.isLoading());
    }

    return {
        isLoading,
        setLoading: globalLoadingController,
        isReady: true
    };
}
