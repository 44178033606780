
import {NavBar} from "./NavBar";
import {GlobalDialogProvider, GlobalDialogRenderer} from "./Dialog/GlobalDialogs";

export function SiteContainer(props) {
    return (<div>
        <GlobalDialogProvider>
            <GlobalDialogRenderer />
            <div id="wrapper">
                {props.renderNavBar !== false && <NavBar />}
                <div id="content-wrapper" className="d-flex flex-column">
                    {props.children}
                    {props.renderFooter !== false && <footer className="sticky-footer bg-white">
                        <div className="container my-auto">
                            <div className="copyright text-center my-auto">
                                <span>Copyright &copy; TrapMaster {(new Date()).getFullYear()}</span>
                            </div>
                        </div>
                    </footer>}
                </div>
            </div>
        </GlobalDialogProvider>
    </div>);
}
