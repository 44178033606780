import LoginLogo from "../assets/logo/logo_vertical_512.png";

export function AuthPage({children, withLogo}) {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        {withLogo === true && <div className="text-center">
                                            <img className="mb-4" src={LoginLogo} alt="TrapMaster Online Logo" style={{width: '100%'}} />
                                        </div>}
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}