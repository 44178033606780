import {PageHeader} from "../component/PageHeader";
import {useNavigate} from "react-router-dom";
import {BasicCard} from "../component/Card/BasicCard";
import {useEffect, useRef, useState} from "react";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {DataTable} from "../component/DataTable";
import {useGlobalDialogs} from "../component/Dialog/GlobalDialogs";
import {BasicModal} from "../component/Dialog/BasicModal";
import {Sleep} from "../common/Util";
import {TextInputWithIcon} from "../component/Form/TextInputWithIcon";
import {ButtonWithTooltip} from "../component/Button/ButtonWithTooltip";

export function ProjectsPage() {
    const database = useDatabase();
    const navigate = useNavigate();
    const globalDialog = useGlobalDialogs();
    const [isLoading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);

    // Load in my projects...
    useEffect(() => {
        setLoading(true);
        const subscription = database.get('project').query().observeWithColumns(['name', 'locked']).subscribe(projects => {
            console.log("Updating projects!", projects);
            setProjects(projects);
            setLoading(false);
        });
        return () => subscription.unsubscribe();
    }, [database]);

    return <div>
        <PageHeader title="My Projects">
            <button className="btn btn-success btn-icon-split" onClick={() => {
                globalDialog.showDialog(<ProjectDetailsDialog
                    onSave={() => globalDialog.closeDialog()}
                    onFinished={() => globalDialog.closeDialog()}
                />);
            }}>
                <span className="text">New Project</span>
                <span className="icon text-white-50">
                        <i className="fas fa-plus"/>
                    </span>
            </button>
        </PageHeader>

        <div className="row">
            <div className="col-12">
                <BasicCard
                    isLoading={isLoading}
                    loadingMessage="Loading Projects..."
                >
                    <ProjectsTable
                        projects={projects}
                        onRequestEdit={(project) => {
                            globalDialog.showDialog(<ProjectDetailsDialog
                                project={project}
                                onSave={() => globalDialog.closeDialog()}
                                onFinished={() => globalDialog.closeDialog()}
                            />);
                        }}
                        onRequestLock={(project) => {
                            globalDialog.showConfirmDialog({
                                confirmText: "Lock Project",
                                onConfirm: async () => {
                                    await globalDialog.withLoading(async () => {
                                        await project.lock();
                                        await Sleep(300);
                                    }, "Locking Project...");
                                }
                            }, <div>
                                <p>Locking a project will cause it to be invisible throughout TrapMaster projects. However, the data is maintained for existing reports and holistic records.</p>
                                <hr/>
                                <h5>Once locked</h5>
                                <ul>
                                    <li>This project will not be visible within the TrapMaster Mobile App.</li>
                                    <li>This project cannot be used to generate new reports.</li>
                                    <li>Traps for this project will not be displayed on the Map.</li>
                                </ul>
                            </div>);
                        }}
                        onRequestUnlock={(project) => project.unlock()}
                        onRequestDelete={(project) => {
                            globalDialog.showConfirmDialog({
                                message: "Are you sure you want to delete this project?",
                                confirmText: "Delete Project",
                                onConfirm: async () => {
                                    await globalDialog.withLoading(async () => {
                                        await project.delete();
                                        await Sleep(300);
                                    }, "Deleting Project...");
                                }
                            });
                        }}
                    />
                </BasicCard>
            </div>
        </div>
    </div>
}

function ProjectsTable({projects, onRequestEdit, onRequestUnlock, onRequestLock, onRequestDelete}) {
    return (
        <DataTable selector="#dataTable">
            <thead>
            <tr>
                <th>Name</th>
                <th style={{width: '165px'}}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {projects.length
                ? projects.map(project => {
                    return (
                        <tr key={project.id}>
                            <td>{project.name}</td>
                            <td>
                                <ButtonWithTooltip
                                    className="btn btn-info float-right"
                                    title="Edit Project"
                                    onClick={() => onRequestEdit(project)}
                                >
                                    <i className="fas fa-pencil-alt" />
                                </ButtonWithTooltip>

                                {project.locked
                                    ? <ButtonWithTooltip
                                        className="btn btn-warning float-right mr-1"
                                        title="Unlock Project"
                                        onClick={() => onRequestUnlock(project)}
                                    ><i className="fas fa-lock" /></ButtonWithTooltip>
                                    : <ButtonWithTooltip
                                        className="btn btn-success float-right mr-1"
                                        title="Lock Project"
                                        onClick={() => onRequestLock(project)}
                                    ><i className="fas fa-lock-open" /></ButtonWithTooltip>
                                }

                                <ButtonWithTooltip
                                    className="btn btn-danger float-right mr-1"
                                    title={ project.cannotDelete ? "Cannot delete Project" : "Delete Project"}
                                    onClick={() => onRequestDelete(project)} disabled={project.cannotDelete}
                                >
                                    <i className="fas fa-trash" />
                                </ButtonWithTooltip>


                            </td>
                        </tr>
                    );
                })
                : <tr>
                    <td colSpan={4}>No projects</td>
                </tr>
            }
            </tbody>
        </DataTable>
    );
}

function ProjectDetailsDialog({project, onSave, onFinished}) {
    const database = useDatabase();
    const [isSaving, setIsSaving] = useState(false);

    const inputProjectName = useRef();

    async function saveChanges() {
        setIsSaving(true);
        try {
            await database.write(async () => {
                if (project) {
                    // Save Project
                    await project.update(update => {
                        update.name = inputProjectName.current.value;
                        update.cannotDelete = true;
                    });
                }
                else {
                    // Create new project.
                    await database.get('project').create(create => {
                        create.name = inputProjectName.current.value
                    });
                }
            });

        } catch (err) {
            // TODO: Show alert message, etc.
            console.error("Failed to save project...");
        }
        finally {
            setIsSaving(false);
        }

        if (onSave) onSave();
    }

    return (
        <BasicModal
            title={(project ? "Edit" : "New") + " Project"}
            onClose={() => onFinished()}
            isLoading={isSaving}
            loadingMessage={"Saving Project..."}
            buttons={<>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => onFinished()}
                >Cancel</button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => saveChanges()}
                >{project ? "Save Changes" : "Create Project"}</button>
            </>}
        >
            <TextInputWithIcon
                inputRef={inputProjectName}
                icon="fa fa-folder"
                title="Name"
                placeholder="Project Name..."
                defaultValue={project?.name}
            />
        </BasicModal>
    );
}