import {Model, Q} from "@nozbe/watermelondb";
import {
    date,
    readonly,
    text,
    field,
    immutableRelation,
    writer,
    children,
    relation
} from "@nozbe/watermelondb/decorators";

export class MarkerModel extends Model {
    static table = "marker";

    static associations = {
        project: { type: 'belongs_to', key: 'project_id' },
        event: { type: 'has_many', foreignKey: 'marker_id' }
    }

    @field('type') type;
    @field('latitude') latitude;
    @field('longitude') longitude;

    @field('project_id') project_id;
    @field('created_by') created_by;

    @immutableRelation('project', 'project_id') project;
    @immutableRelation('account', 'created_by') createdBy;

    // @readonly @date('created_at') createdAt;
    @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
    @date('removed_at') removedAt;

    @children('event') events;

    async fetchEvents(type=null) {
        const conditions = [Q.where('marker_id', this.id)];
        if (type) conditions.push(Q.where('type', type));
        return this.collections.get('event').query(...conditions).fetch();
    }

    @writer async delete() {
        await this.markAsDeleted();
    }
}
