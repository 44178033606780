export function CheckBoxCloud({name, checked, onChange}) {
    return (
        <div style={{display: 'inline-block'}} className="mr-2 mb-2">
            <div className="input-group">
                <span className="input-group-text">{name}</span>
                <div className="input-group-append">
                    <div className="input-group-text">
                        <input type="checkbox" checked={checked} onChange={(e) => {
                            onChange(e.target.checked);
                        }} />
                    </div>
                </div>
            </div>
        </div>
    );
}