import {PageHeader} from "../../component/PageHeader";
import React, {useEffect, useRef, useState} from "react";
import {DateRangePicker} from "../../component/DateRangePicker/DateRangePicker";
import {SimpleWizard} from "../../component/SimpleWizard/SimpleWizard";
import {ValidationError} from "../../common/ValidationError";
import {MapView} from "../../component/Map/MapView";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import {Sleep} from "../../common/Util";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {Q} from "@nozbe/watermelondb";
import {BasicCard} from "../../component/Card/BasicCard";
import {SelectWithPlaceholder} from "../../component/Form/SelectWithPlaceholder";
import {useNavigate} from "react-router-dom";
import {ProjectReport} from "../../component/Report/ProjectReport";
import _, {parseInt} from "lodash";
import {MarkerLayer} from "../../component/Map/Layers/MarkerLayer";
import {CreateLogger} from "../../common/logger/Logger";
import {EventTypes} from "../../config/EventTypes";
import {MarkerTypes} from "../../config/MarkerTypes";
import {GeoJson} from "../../common/map";
import {Loading} from "../../component/Loading/Loading";

const logger = CreateLogger("ReportWizard");

export function NewReportPage() {
    const database = useDatabase();
    const navigate = useNavigate();
    let [project, setProject] = useState("");
    let [geoBounds, setGeoBounds] = useState(null);
    let [dates, setDates] = useState({ startDate: null, endDate: null });
    let [companyName, setCompanyName] = useState("");
    let [reportName, setReportName] = useState("");
    let [comments, setComments] = useState("");
    let [markers, setMarkers] = useState([]);
    const [enabledFeatures, setEnabledFeatures] = useState({
        [MarkerTypes.TRAP]: true,
        [MarkerTypes.POISON]: true,
        [MarkerTypes.CATCH]: true,
        [MarkerTypes.SIGHTING]: false,
        [MarkerTypes.HAZARD]: false,
        [MarkerTypes.TASK]: false,
    });

    return (
        <div>
            <PageHeader title="New Report"/>
            <SimpleWizard
                finishedText="Generate Report"
                finishedIcon="fas fa-clipboard"
                stages={[
                    ReportProjectSection,
                    ReportDateSection,
                    ReportGeoBoundsSection,
                    ReportDetailsSection,
                    ReportPreviewSection,
                ]}
                stageProps={{
                    project, setProject,
                    dates, setDates,
                    geoBounds, setGeoBounds,
                    companyName, setCompanyName,
                    comments, setComments,
                    enabledFeatures, setEnabledFeatures,
                    reportName, setReportName
                }}
                onComplete={async () => {
                    try {
                        let reportID = null;
                        await database.write(async () => {
                            const report = await database.get('report').create((report) => {
                                report.name = reportName;
                                report.project.set(project);
                                report.companyName = companyName;
                                report.dateStart = dates.startDate;
                                report.dateEnd = dates.endDate;
                                report.options = {
                                    dates: dates,
                                    geo: geoBounds,
                                    comments: comments,
                                    enabledFeatures: _.pickBy(enabledFeatures, value => !!value),
                                }
                            });
                            reportID = report.id;
                        });

                        navigate('/reports/' + reportID);
                    } catch (err) {
                        // TODO: Nice error dialog here!
                        console.error("Failed to generate report!");
                    }

                    console.log("Wizard Finished!");
                }}
            />
        </div>
    );
}

/** Ask the user to select the project they want to use. **/
function ReportProjectSection({project, setProject, beforeNext}) {
    const database = useDatabase();
    let [validationErrors, setValidationErrors] = useState([]);
    let [isLoading, setLoading] = useState(true);
    let [projects, setProjects] = useState(new Map());

    useEffect(() => {
        setLoading(true);
        const subscription = database.get('project').query(
            Q.where('locked', false)
        ).observeWithColumns(['name']).subscribe((projects) => {
            const projectsMap = new Map();
            for (project of projects) projectsMap.set(project.id, project);
            setProjects(projectsMap);
            setLoading(false);
        });

        return () => subscription.unsubscribe();
    }, [database]);

    // Register a before next register for validation.
    beforeNext(() => {
        let errors = [];
        if (!project) errors.push(new ValidationError("You must select a project."));
        setValidationErrors(errors);
        return errors.length === 0;
    });

    const errorMessage = validationErrors.map(err => <p key={err.message} style={{color: 'red'}}>{err.message}</p>);

    return (
        <BasicCard
        title="Select a Project"
        isLoading={isLoading}
        loadingMessage="Loading Projects...">
            {errorMessage}
            <div className="form-group">
                <label htmlFor="reportProjectInput">Please select the project you want to base this report on:</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                            <i className="fa fa-folder"/>
                        </span>
                    </div>
                    <SelectWithPlaceholder placeholder="Please select a project..." value={project?.id} onChange={(e) => {
                        const projectID = e.target.value;
                        if (projects.has(projectID)) setProject(projects.get(projectID));
                    }}>
                        {Array.from(projects.values()).map(project => {
                            return (<option key={project.id} value={project.id}>{project.name}</option>);
                        })}
                    </SelectWithPlaceholder>
                </div>
            </div>
        </BasicCard>
    );
}

/** Ask the user to enter the report start -> end dates **/
function ReportDateSection({dates, setDates, beforeNext}) {
    let [validationErrors, setValidationErrors] = useState([]);

    // Register a before next register for validation.
    beforeNext(() => {
        let errors = [];
        if (!dates.startDate) errors.push(new ValidationError("Start date is not set."));
        if (!dates.endDate) errors.push(new ValidationError("End date is not set."));
        setValidationErrors(errors);

        return errors.length === 0;
    });

    const errorMessage = validationErrors.map(err => <p key={err.message} style={{color: 'red'}}>{err.message}</p>)

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Select Dates</h6>
                    </div>
                    <div className="card-body">
                        {errorMessage}
                        <div className="form-group">
                            <label htmlFor="reportProjectInput">Please select the start and end dates for this report:</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fa fa-calendar" />
                                </span>
                                </div>
                                <DateRangePicker
                                    startDate={dates.startDate}
                                    endDate={dates.endDate}
                                    pickerOptions={{
                                        linkedCalendars: false
                                    }}
                                    onChange={setDates}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

/** Ask the user to select geographical bounds for their report **/
function ReportGeoBoundsSection({project, dates, geoBounds, setGeoBounds, beforeNext, enabledFeatures, setEnabledFeatures}) {
    const database = useDatabase();
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [markers, setMarkers] = useState([]);
    const [geojson, setGeoJson] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const allowedtypes = Object.keys(enabledFeatures).reduce((allowedtypes, type) => {
            logger.info("Iterator Type: ", type);
            if (enabledFeatures[type]) allowedtypes.push(parseInt(type));
            return allowedtypes;
        }, []);

        logger.info("Allowed Marker Types: ", allowedtypes);

        const subscription = database.get('marker').query(
            Q.where('project_id', project.id),
            Q.where('created_at', Q.gte(dates.startDate.getTime())),
            Q.where('created_at', Q.lt(dates.endDate.getTime())),
            Q.where('type', Q.oneOf(allowedtypes))
        ).observe().subscribe(frame => {
            logger.log("Loaded Markers: ", frame);
            setMarkers(frame);
            setGeoJson(GeoJson(frame));
            setIsLoading(false);
        });

        return () => subscription.unsubscribe();
    }, [project, dates, database, enabledFeatures]);

    // Register a before next register for validation.
    beforeNext(() => {
        let errors = [];
        if (!geoBounds) errors.push(new ValidationError("You must outline a block for the report."));
        else if (geoBounds.features.length !== 1) errors.push(new ValidationError("You must outline a single block."));
        setValidationErrors(errors);
        return errors.length === 0;
    });

    const errorMessage = validationErrors.map(err => <p key={err.message} className="p-2" style={{color: 'red'}}>{err.message}</p>)

    const mapboxDraw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            polygon: true,
            trash: true
        },
        defaultMode: geoBounds ? 'simple_select' : 'draw_polygon'
    });

    function handlePolygonUpdate(e) {
        setGeoBounds(mapboxDraw.getAll());
    }

    function FeatureTypeControl({type, name}) {
        return (
            <div style={{display: 'inline-block'}} className="mr-2 mb-2">
                <div className="input-group">
                    <span className="input-group-text">{name}</span>
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <input type="checkbox" checked={enabledFeatures[type]} onChange={(e) => {
                                const enabled = e.target.checked;
                                setEnabledFeatures({
                                    ...enabledFeatures,
                                    [type]: enabled
                                })
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BasicCard
        title="Select Geographical Bounds"
        isLoading={isLoading}
        loadingMessage="Loading Events..."
        bodyClassName="p-0"
        >
            {errorMessage}
            <div className="p-4">
                <FeatureTypeControl type={MarkerTypes.TRAP} name="Traps"/>
                <FeatureTypeControl type={MarkerTypes.POISON} name="Bait Stations"/>
                <FeatureTypeControl type={MarkerTypes.CATCH} name="Catches"/>
                <FeatureTypeControl type={MarkerTypes.SIGHTING} name="Sightings"/>
                <FeatureTypeControl type={MarkerTypes.HAZARD} name="Hazards"/>
                <FeatureTypeControl type={MarkerTypes.TASK} name="Tasks"/>
            </div>
            <div style={{
                width: '100%',
                height: '600px',
            }}>
                { geojson === false
                    ? <Loading message="Loading Markers" />
                    : <MapView
                        bounds={geojson.bounds}
                        onInit={(map) => {
                            map.addControl(mapboxDraw);
                            map.on('draw.create', handlePolygonUpdate);
                            map.on('draw.delete', handlePolygonUpdate);
                            map.on('draw.update', handlePolygonUpdate);
                        }}
                        onLoad={() => {
                            if (geoBounds) mapboxDraw.set(geoBounds);
                        }}
                    >
                        <MarkerLayer geojson={geojson} />
                    </MapView>
                }
            </div>
        </BasicCard>
    );
}

function ReportDetailsSection({beforeNext, reportName, setReportName, companyName, setCompanyName, comments, setComments}) {
    let [validationErrors, setValidationErrors] = useState([]);

    beforeNext(() => {
        const errors = [];
        if (!companyName) errors.push(new ValidationError("Company name cannot be blank."));
        setValidationErrors(errors);
        return errors.length === 0;
    });

    const errorMessage = validationErrors.map(err => <p key={err.message} style={{color: 'red'}}>{err.message}</p>);

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Report Details</h6>
                    </div>
                    <div className="card-body">
                        {errorMessage}
                        <div className="form-group">
                            <label htmlFor="reportCompanyNameInput">Report Name</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-building"/>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="reportReportNameInput"
                                    placeholder="Report Name..."
                                    value={reportName}
                                    onChange={(e) => {
                                        setReportName(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="reportCompanyNameInput">Company Name</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-building"/>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="reportCompanyNameInput"
                                    placeholder="Company Name..."
                                    value={companyName}
                                    onChange={(e) => {
                                        setCompanyName(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="reportCommentsInput">Report Comments</label>
                            <textarea
                                className="form-control"
                                id="reportCommentsInput"
                                placeholder="Anything you would like to mention?"
                                rows={5}
                                cols={30}
                                onChange={(e) => {
                                    setComments(e.target.value);
                                }}
                                value={comments}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ReportPreviewSection({project, dates, geoBounds, companyName, reportName, comments}) {

    return (
        <div className="row">
            <div className="col-lg-12">
                <BasicCard
                    title="Report Preview"
                >
                    <ProjectReport
                        project={project.id}
                        dates={dates}
                        geoBounds={geoBounds}
                        companyName={companyName}
                        comments={comments}
                        reportName={reportName}
                    />
                </BasicCard>
            </div>
        </div>
    );
}