import {createContext, useContext, useEffect, useState} from "react";
import {TimeFeed} from "../../common/data/TimeFeed";
import {FireStore} from "../../config/firebase";

const EventStatsContext = createContext({
    isReady: false
});

export function useEventStats() {
    return useContext(EventStatsContext);
}

export function EventStatsProvider({children}) {
    const context = PrepareEventStats();
    return <EventStatsContext.Provider value={context}>{children}</EventStatsContext.Provider>
}

function PrepareEventStats() {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        setIsReady(true);
    }, []);

    const EventsFeed = new TimeFeed(FireStore.collectionGroup('events'), 'timestamp');
    const CaughtFeed = new TimeFeed(FireStore.collectionGroup('events').where('type', '==', 3), 'timestamp');
    const SprungFeed = new TimeFeed(FireStore.collectionGroup('events').where('type', '==', 4), 'timestamp');
    return {
        isReady,
        EventsFeed,
        CaughtFeed,
        SprungFeed,
    };
}