import {PageHeader} from "../component/PageHeader";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {useEffect, useRef, useState} from "react";
import {DataTable} from "../component/DataTable";
import {BasicModal} from "../component/Dialog/BasicModal";
import {TextInputWithIcon} from "../component/Form/TextInputWithIcon";
import {Sleep} from "../common/Util";
import {InputGroupWithIcon} from "../component/Form/InputGroupWithIcon";
import {InputWithTitle} from "../component/Form/InputWithTitle";
import {BasicCard} from "../component/Card/BasicCard";
import {useGlobalDialogs} from "../component/Dialog/GlobalDialogs";
import {ButtonWithTooltip} from "../component/Button/ButtonWithTooltip";
import firebase from "firebase";
import {useAuth} from "../common/Auth";
import {FireStore} from "../config/firebase";

export function UsersPage() {
    const auth = useAuth();
    const globalDialogs = useGlobalDialogs();
    const database = useDatabase();
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [isEditUserDialog, setEditUserDialog] = useState(false);
    const [targetUser, setTargetUser] = useState(null);
    const [isNewUserDialog, setNewUserDialog] = useState(false);

    // Load in my users...
    useEffect(() => {
        setUsers([]);
        setLoading(true);
        const subscription = FireStore.collection('users').where('account', '==', auth.user.get('account')).onSnapshot((users) => {
            console.log("Updating Users!", users);
            setUsers(users.docs);
            setLoading(false);
        });

        return subscription;
    }, [auth]);

    function onEditUser(user) {
        console.log("request to edit user.");
        setTargetUser(user);
        setEditUserDialog(true);
    }

    function onLockUser(user) {
        console.log(user);
        globalDialogs.showConfirmDialog({
            message: "Are you sure you want to lock user: " + user.get('email'),
            confirmText: "Lock User",
            onConfirm: async () => {
                console.log("Locking User...");
                user.ref.set({isActive: false}, {merge: true});
            }
        })
    }

    function onUnlockUser(user) {
        globalDialogs.showConfirmDialog({
            message: "Are you sure you want to unlock user: " + user.get('email'),
            confirmText: "Unlock User",
            onConfirm: async () => {
                console.log("Unlocking User...");
                user.ref.set({isActive: true}, {merge: true});
            }
        })
    }

    function onNewUser() {
        setNewUserDialog(true);
    }

    return (
        <div>
            <PageHeader title="Users">
                <button className="btn btn-success btn-icon-split" disabled={true} onClick={(e) => onNewUser()}>
                    <span className="text">New User</span>
                    <span className="icon text-white-50">
                        <i className="fas fa-plus"/>
                    </span>
                </button>
            </PageHeader>
            <div className="row">
                <div className="col-12">
                    <BasicCard
                        isLoading={isLoading}
                        loadingMessage="Loading Users..."
                    >
                        <UsersTable users={users} setLoading={setLoading} onEditUser={onEditUser} onRequestUserLock={onLockUser} onRequestUserUnlock={onUnlockUser}/>
                    </BasicCard>
                </div>
            </div>

            {isNewUserDialog && <NewUserDialog
                onClose={() => setNewUserDialog(false)}
                onSave={() => setNewUserDialog(false)}
            />}

            {targetUser && isEditUserDialog && <EditUserDialog
                user={targetUser}
                onClose={() => setEditUserDialog(false)}
                onSave={() => setEditUserDialog(false)}
            />}
        </div>
    );
}

function UsersTable({users, onEditUser, onRequestUserLock, onRequestUserUnlock}) {
    const rows = [];
    for (const user of users) {
        rows.push(<tr key={user.id}>
            <td>{user.get('fullName')}</td>
            <td>{user.get('email')}</td>
            <td>
                <button className="btn btn-info float-right" onClick={() => onEditUser(user)}>
                    <i className="fas fa-pencil-alt" />
                </button>
                {user.get('isActive')
                    ? <ButtonWithTooltip
                        className="btn btn-success float-right mr-1"
                        title="User is Unlocked"
                        onClick={() => onRequestUserLock(user)}
                    ><i className="fas fa-lock-open" /></ButtonWithTooltip>
                    : <ButtonWithTooltip
                        className="btn btn-warning float-right mr-1"
                        title="User is Locked"
                        onClick={() => onRequestUserUnlock(user)}
                    ><i className="fas fa-lock" /></ButtonWithTooltip>
                }
            </td>
        </tr>);
    }

    return (
        <DataTable>
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th style={{width: '115px'}}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {users.length ? rows : <tr><td colSpan={3}>No Users...</td></tr>}
            </tbody>
        </DataTable>
    );
}

function EditUserDialog({user, onClose, onSave}) {
    const [isSaving, setIsSaving] = useState(false);

    const inputName = useRef();

    async function saveChanges() {
        console.log("Save User Changes.");
        setIsSaving(true);
        user.ref.set({
            fullName: inputName.current.value,
        }, {merge: true});

        await Sleep(300);
        setIsSaving(false);
        if (onSave) onSave();
    }

    return (
        <BasicModal
            title="Edit User"
            onClose={() => onClose()}
            isLoading={isSaving}
            loadingMessage={"Saving User..."}
            buttons={<>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                        onClose();
                    }}
                >Cancel</button>,
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => saveChanges()}
                >Save changes</button>
            </>}
        >
        <TextInputWithIcon
            inputRef={inputName}
            icon="fa fa-user"
            title="Name"
            placeholder="Please enter the name for this user..."
            defaultValue={user.get('fullName')}
            />
        </BasicModal>
    );
}

function NewUserDialog({onClose, onSave}) {
    const database = useDatabase();
    const [isSaving, setIsSaving] = useState(false);

    const inputFirstName = useRef();
    const inputLastName = useRef();
    const inputEmail = useRef();

    async function saveChanges() {
        console.log("Save User Changes.");
        setIsSaving(true);
        // await database.write(async () => {
        //     await database.get('user').create(user => {
        //         user.name = inputFirstName.current.value;
        //         user.lastName = inputLastName.current.value;
        //         user.email = inputEmail.current.value;
        //         user.isActive = true;
        //     })
        // });

        await Sleep(300);
        setIsSaving(false);
        if (onSave) onSave();
    }

    return (
        <BasicModal
            title="New User"
            onClose={() => onClose()}
            isLoading={isSaving}
            loadingMessage={"Saving User..."}
            buttons={<>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                        onClose();
                    }}
                >Cancel</button>,
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => saveChanges()}
                >Create User</button>
            </>}
        >
            <TextInputWithIcon
                inputRef={inputFirstName}
                icon="fa fa-user"
                title="First Name"
                placeholder="First Name..."
            />
            <TextInputWithIcon
                inputRef={inputLastName}
                icon="fa fa-user"
                title="Last Name"
                placeholder="Last Name..."
            />

            <InputWithTitle title="Email">
                <InputGroupWithIcon icon="fa fa-envelope">
                    <input
                        ref={inputEmail}
                        type="email"
                        className="form-control"
                        placeholder="Email..."
                    />
                </InputGroupWithIcon>
            </InputWithTitle>
        </BasicModal>
    );
}