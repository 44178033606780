import {PageHeader} from "../../component/PageHeader";
import {useNavigate} from "react-router-dom";
import {BasicCard} from "../../component/Card/BasicCard";
import {useEffect, useState} from "react";
import {DataTable} from "../../component/DataTable";
import {ButtonWithTooltip} from "../../component/Button/ButtonWithTooltip";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {Sleep} from "../../common/Util";
import {useGlobalDialogs} from "../../component/Dialog/GlobalDialogs";
import { switchMap, map } from 'rxjs/operators'
import { zip, of } from 'rxjs';


export function ReportsPage() {
    const globalDialog = useGlobalDialogs();
    const database = useDatabase();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        setLoading(true);
        const subscription = database.get('report').query().observeWithColumns(['name', 'project_id']).pipe(
            switchMap(reports => {
                if (!reports.length) return of([]);
                return zip(...reports.map(report => report.project.observe().pipe(map(project => {
                    return {
                        report: report,
                        project: project
                    }
                }))));
            })
        ).subscribe(async reports => {
            console.log("Updated Reports: ", reports);
            setReports(reports);
            setLoading(false);
        });
        return () => subscription.unsubscribe();
    }, [database]);

    return (
        <div>
            <PageHeader title="My Reports">
                <button className="btn btn-success btn-icon-split" onClick={() => navigate('new')}>
                    <span className="text">New Report</span>
                    <span className="icon text-white-50">
                            <i className="fas fa-plus"/>
                        </span>
                </button>
            </PageHeader>

            <div className="row">
                <div className="col-12">
                    <BasicCard
                        isLoading={isLoading}
                        loadingMessage="Loading Reports..."
                    >
                        <ReportsTable
                            reports={reports}
                            onRequestDelete={(report) => {
                                globalDialog.showConfirmDialog({
                                    message: "Are you sure you want to delete this report?",
                                    confirmText: "Delete Report",
                                    onConfirm: async () => {
                                        await globalDialog.withLoading(async () => {
                                            await report.delete();
                                            await Sleep(300);
                                        }, "Deleting Report...");
                                    }
                                });
                            }}
                            onRequestOpen={(report) => {
                                navigate('/reports/' + report.id);
                            }}
                        />
                    </BasicCard>
                </div>
            </div>
        </div>
    );
}

function ReportsTable({reports, onRequestOpen, onRequestDelete}) {
    return (
        <DataTable selector="#dataTable">
            <thead>
            <tr>

                <th>Name</th>
                <th>Project</th>
                <th>Company</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Created</th>
                <th style={{width: '115px'}}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {reports.length
                ? reports.map(({report, project}) => {
                    return (
                        <tr key={report.id}>
                            <td>{report.name}</td>
                            <td>{project.name}</td>
                            <td>{report.companyName}</td>
                            <td>{report.dateStart?.toLocaleString()}</td>
                            <td>{report.dateEnd?.toLocaleString()}</td>
                            <td>{report.createdAt.toLocaleString()}</td>
                            <td>
                                <ButtonWithTooltip
                                    className="btn btn-info float-right"
                                    title="Edit Project"
                                    onClick={() => onRequestOpen(report)}
                                ><i className="fas fa-eye" /></ButtonWithTooltip>

                                <ButtonWithTooltip
                                    className="btn btn-danger float-right mr-1"
                                    title="Delete Report"
                                    onClick={() => onRequestDelete(report)}
                                ><i className="fas fa-trash" /></ButtonWithTooltip>

                            </td>
                        </tr>
                    );
                })
                : <tr>
                    <td colSpan={7}>You have no reports...</td>
                </tr>
            }
            </tbody>
        </DataTable>
    );
}