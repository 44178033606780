import {CreateLogger} from "../common/logger/Logger";
import _ from 'lodash'
import {database} from "../common/DB";
import {EventTypes} from "../config/EventTypes";

const logger = CreateLogger('ImportData');

const OldEventTypes = Object.freeze({
    MISC: 0,
    TRAP_SET: 1,
    TRAP_PICKUP: 2,
    TRAP_CATCH: 3,
    TRAP_SPRUNG: 4,
    MARKER_PLACED: 5,
    MARKER_REMOVED: 6,
});

const EventTypeMap = Object.freeze({
    [OldEventTypes.TRAP_SET]: EventTypes.TRAP_PLACED,
    [OldEventTypes.TRAP_PICKUP]: EventTypes.TRAP_PICKUP,
    [OldEventTypes.TRAP_SPRUNG]: EventTypes.TRAP_SPRUNG,
    [OldEventTypes.TRAP_CATCH]: EventTypes.TRAP_CATCH,
});

export async function importData(data) {
    logger.info("Data: ", data);

    await database.write(async () => {
        // Write projects.
        await Promise.all(_.map(data.projects, async (project) => {
            const newProject = await database.get('project').create(record => {
                record._raw.id = project.id;
                record.cannotDelete = true;
                record.name = project.name;
                record.locked = false;
            });

            // Link account to project.
            const AccountProjectLink = await database.get('account_project').create(record => {
               record.accountID = window.auth_account.id;
               record.projectID = newProject.id;
            });
        }));

        // Write markers.
        await Promise.all(_.map(data.markers, async (marker) => {
            await database.get('marker').create(record => {
                record._raw.id = marker.id;
                record.type = marker.type;
                record.longitude = marker.longitude;
                record.latitude = marker.latitude;
                record.project_id = marker.project_id;
                record.created_by = marker.created_by;
                record.createdAt = marker.created_at;
                record.removedAt = marker.removed_at;
            });
        }));

        //Write Events
        await Promise.all(_.map(data.events, async (event) => {
            let newType = event.type;
            if (EventTypeMap.hasOwnProperty(event.type)) {
                newType = EventTypeMap[event.type];
            }

            await database.get('event').create(record => {
                record._raw.id = event.id;
                record.type = newType;
                record.projectID = event.project_id;
                record.longitude = event.longitude;
                record.latitude = event.latitude;
                record.markerID = event.marker_id;
                record.createdAt = event.timestamp;
                record.userID = event.created_by;
            });
        }));
    });
}