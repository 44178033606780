import {Model} from "@nozbe/watermelondb";
import {date, readonly, text, field, json, immutableRelation, writer} from "@nozbe/watermelondb/decorators";

export class ReportModel extends Model {
    static table = "report";

    @text('name') name;
    @field('company_name') companyName;

    @field('project_id') projectID;

    @json('options', json => json) options;

    @date('date_start') dateStart;
    @date('date_end') dateEnd;

    @immutableRelation('project', 'project_id') project;

    @readonly @date('created_at') createdAt
    @readonly @date('updated_at') updatedAt

    @writer async delete() {
        await this.markAsDeleted();
    }
}
