export class MapboxGLButtonControl {
    constructor({className = "", eventHandler = () => console.log("Control button clicked!"), title = ""}) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
        this._map = null;
    }

    onAdd(map) {
        this._map = map;
        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon " + this._className;
        this._btn.type = "button";
        this._btn.title = this._title;
        this._btn.onclick = (e) => {
            this.handleClick(e);
        }

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }

    handleClick(e) {
        if (this._eventHandler) this._eventHandler(e);
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}