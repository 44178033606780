import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC04Yqp2XBQ949xBt_2l7rCz36xehB3_vs",
    authDomain: "trapmaster2-aeaff.firebaseapp.com",
    projectId: "trapmaster2-aeaff",
    storageBucket: "trapmaster2-aeaff.appspot.com",
    messagingSenderId: "724830802293",
    appId: "1:724830802293:web:e3e17a7ac5c841a573433c",
    measurementId: "G-9WSX9WBFEK"
};

// const firebaseConfig = {
//     apiKey: "AIzaSyCbp5tIuXmqHvpfeMCTRpfZZP5JxfYGCRU",
//     authDomain: "trapmaster-15ba9.firebaseapp.com",
//     projectId: "trapmaster-15ba9",
//     storageBucket: "trapmaster-15ba9.appspot.com",
//     messagingSenderId: "919834322774",
//     appId: "1:919834322774:web:724431f23dbe383233d8f7",
//     measurementId: "G-97WKY27QCM"
// };

const app = firebase.initializeApp(firebaseConfig);

const FireAuth = app.auth();
const FireStore = app.firestore();
const FireStorage = app.storage();

export {
    app,
    FireAuth,
    FireStore,
    FireStorage,
}
