import {PageHeader} from "../../component/PageHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BasicCard} from "../../component/Card/BasicCard";
import React, {useCallback, useEffect, useState} from "react";
import {useDatabase} from "@nozbe/watermelondb/hooks";
import {Sleep} from "../../common/Util";
import {useGlobalDialogs} from "../../component/Dialog/GlobalDialogs";
import {ProjectReport} from "../../component/Report/ProjectReport";
import {DocumentViewer} from "../../component/Printable/PrintableDocument";
import {CreateLogger} from "../../common/logger/Logger";

const logger = CreateLogger('ReportPage');
export function ReportPage() {
    const params = useParams();
    const location = useLocation();
    const globalDialog = useGlobalDialogs();
    const database = useDatabase();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [reportData, setReportDate] = useState(null);
    const [isFullScreen, setFullScreen] = useState(false);

    const close = useCallback(() => setFullScreen(false));

    useEffect(() => {
        // Load report.
        setLoading(true);
        const subscriber = database.get('report').findAndObserve(params.reportID).subscribe(async reportData => {
            setReportDate(reportData);
            setLoading(false);
        })

        return () => subscriber.unsubscribe();
    }, []);

    function generateReport(options) {
        options = Object.assign({}, options);
        if (!reportData) return <div>ERROR: Report Data Invalid.</div>;
        logger.info("ReportData: ", reportData);
        return (
            <ProjectReport
                project={reportData.projectID}
                geoBounds={reportData.options?.geo}
                dates={{
                    startDate: reportData.dateStart,
                    endDate: reportData.dateEnd
                }}
                reportName={reportData.name}
                companyName={reportData.companyName}
                comments={reportData.options?.comments}
                printable={options.printable || false}
            />
        );
    }

    let portal = null;
    if (isFullScreen) {
        portal = (
            <DocumentViewer onRequestClose={close}>
                {generateReport({printable: true})}
            </DocumentViewer>
        );
    }

    return (
        <div>
            {portal}
            <PageHeader title="Report">
                <div>
                    <button className="btn btn-danger btn-icon-split mr-2" title="Delete this report" onClick={() => {
                        globalDialog.showConfirmDialog({
                            confirmText: "Delete",
                            onConfirm: async () => {
                                console.log("Delete report...");
                                await database.write(async () => {
                                    await reportData.markAsDeleted();
                                });
                                await Sleep(300);
                                navigate('/reports');
                            }
                        }, <div>
                            <h5>Are you sure you want to delete this report?</h5>
                            <ul>
                                <li>Anyone you have shared this report with will no longer be able to view it.</li>
                            </ul>
                        </div>)
                    }}>
                        <span className="text">Delete</span>
                        <span className="icon text-white-50">
                            <i className="fas fa-trash"/>
                        </span>
                    </button>
                    <button className="btn btn-success btn-icon-split mr-2" title="Edit this report" onClick={() => navigate('edit')}>
                        <span className="text">Edit</span>
                        <span className="icon text-white-50">
                            <i className="fas fa-pencil-alt"/>
                        </span>
                    </button>
                    <button className="btn btn-success btn-icon-split" onClick={() => {
                        setFullScreen(true);
                    }}>
                        <span className="text">PDF</span>
                        <span className="icon text-white-50">
                            <i className="fas fa-book"/>
                        </span>
                    </button>
                </div>

            </PageHeader>

            <div className="row">
                <div className="col-12">
                    <BasicCard
                        isLoading={isLoading}
                        loadingMessage="Loading Report..."
                    >
                        {isLoading ? <></> : generateReport()}
                    </BasicCard>
                </div>
            </div>
        </div>
    );
}
