import {MapView, useMap} from "../component/Map/MapView";
import {useEffect, useRef, useState} from "react";
import {useAuth} from "../common/Auth";
import {MarkerLayer} from "../component/Map/Layers/MarkerLayer";
import {database} from "../common/DB";
import {Q} from "@nozbe/watermelondb";
import {GeoJson} from "../common/map";
import {Loading} from "../component/Loading/Loading";
import {CreateLogger} from "../common/logger/Logger";
import {useSearchParams} from "react-router-dom";
import {MarkerTypes} from "../config/MarkerTypes";

const logger = CreateLogger('MapPage');

export function MapPage() {
    const auth = useAuth();
    const [searchParams] = useSearchParams();

    const [markers, setMarkers] = useState([]);
    const [geojson, setGeoJson] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [enabledMarkerTypes, setEnabledMarkerTypes] = useState({
        [MarkerTypes.TRAP]: true
    });

    let initialProjects = false;
    if (searchParams.has('project')) initialProjects = {[searchParams.get('project')]: true};
    const [enabledProjects, setEnabledProjects] = useState(initialProjects);



    useEffect(() => {
        setIsLoaded(false);
        const conditions = [
            Q.on('project', Q.where('locked', false)),
            Q.where('removed_at', Q.eq(null))
        ];

        if (enabledProjects !== false) {
            const allowedprojectids = [];
            const projectIDs = Object.keys(enabledProjects);
            for (let i = 0; i < projectIDs.length; i++) {
                if (enabledProjects[projectIDs[i]]) allowedprojectids.push(projectIDs[i]);
            }
            conditions.push(Q.where('project_id', Q.oneOf(allowedprojectids)));
        }

        if (enabledMarkerTypes) {
            const allowedMarkerTypes = [];
            const markerTypes = Object.keys(enabledMarkerTypes);
            for (let i = 0; i < markerTypes.length; i++) {
                if (enabledMarkerTypes[markerTypes[i]]) allowedMarkerTypes.push(parseInt(markerTypes[i]));
            }
            conditions.push(Q.where('type', Q.oneOf(allowedMarkerTypes)));
        }


        logger.info("Conditions: ", conditions);
        const subscription = database.get('marker').query(...conditions).observe().subscribe(frame => {
            logger.info("Fetched Markers:", frame);
            setMarkers(frame);
            setGeoJson(GeoJson(frame));
            setIsLoaded(true);
        });

        return () => subscription.unsubscribe();
    }, [auth, enabledProjects, enabledMarkerTypes]);

    return (
        <div style={{
            width: '100%',
            height: '100%'
        }}>
            {isLoaded
                ? <MapView
                    showPitchControl={true}
                    showFullScreenControl={true}
                    showStyleControl={true}
                    showProjectControl={false}
                    bounds={geojson.bounds || false}

                    enabledFeatures={enabledMarkerTypes}
                    setEnabledFeatures={(enabledFeatures) => {
                        setEnabledMarkerTypes(enabledFeatures);
                    }}

                    enabledProjects={enabledProjects}
                    setEnabledProjects={(enabledProjects) => {
                        setEnabledProjects(enabledProjects);
                    }}
                >
                    <MarkerLayer geojson={geojson} />
                </MapView>
                : <Loading message={"Loading Map..."} />
            }
        </div>
    );
}